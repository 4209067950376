/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Column, Row } from "../_shared/styledComponents";
import {
  BodyText,
  BodyText2,
  HeaderGreenTopBar,
  HeaderText,
} from "../_shared/styled";
import BinSvg from "../_shared/assets/svg/Bin.svg";
import InputField from "../_shared/components/InputField";
import DropdownComponent from "../_shared/components/Dropdown";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from "../_shared/api";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import ImageUploadCard from "../_shared/components/imageUploader";
import ImageUpload from "../_shared/components/imageUploader";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { errorMessage, urls } from "../_shared/constants";
import { AddCircle, Delete, RemoveCircle } from "@mui/icons-material";
import CategoriesMultiSelectDropdown from "./CategoriesMultiSelect";
import axios from "axios";

import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
  AdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import PlaceAutocomplete from "./places";
import CreateNewEventPreview from "./NewEventPreview";
import { Helmet } from "react-helmet";
import { sortCurrenciesByValue } from "../_shared/services";
import { time } from "console";
import { max } from "date-fns";

interface CustomField {
  field_name: string;
  is_required: number;
}

interface Ticket {
  id: number;
  name: string;
  otherTicketName: string;
  description: string;
  // currency: string;
  price: number;
  quantity: number;
  ticketAvailabilityDate: string;
  ticketAvailabilityTime: string;
  ticketAvailabilityEndDate: string;
  ticketAvailabilityEndTime: string;
  isNewTicket?: boolean;
  allowCashPayment?: boolean;
  max_cash_payments: number;
}

interface StaffMember {
  mobileNumber: string;
}

interface Group {
  id: number;
  group_name: string;
}

interface SubCategory {
  id: number;
  sub_category_name: string;
  category_id: number;
  date_added: string;
  image_link: string | null;
  status: string;
}
interface Category {
  id: number;
  category_description: string;
  category_name: string;
  date_added: string;
  date_updated: string | null;
  image_link: string;
  status: string;
  sub_categories: SubCategory[];
}

interface Timezone {
  zoneName: string;
}

type TimezoneData = {
  [key: string]: string[];
};

const CreateEventPage = () => {
  const ticketOptions = [
    "Earlybird",
    "General Admission",
    "VIP Pass",
    "Free",
    "Custom",
  ];
  const [visibility, setVisibility] = useState<"public" | "private">("public");
  const [eventName, setEventName] = useState("");
  const [eventCurrency, setEventCurrency] = useState("");
  const [eventImage, setEventImage] = useState<Array<string>>([]);
  const [imageIdsDelete, setImageIdsDelete] = useState<Array<any>>([]);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [ticketAvailabilityDate, setTicketAvailabilityDate] = useState("");
  const [ticketAvailabilityTime, setTicketAvailabilityTime] = useState("");
  const [eventDetails, setEventDetails] = useState<any>(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([]);
  const [currencies, setCurrencies] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState<CustomField[]>([
    // { field_name: "", is_required: 0 },
  ]);
  // const [eventType, setEventType] = useState("In-person");
  const [meetingLink, setMeetingLink] = useState("");
  const [timezoneData, setTimezoneData] = useState<TimezoneData>({});
  const [continent, setContinent] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState("UTC+0");
  const [selectedWaitlistValue, setSelectedWaitlistValue] = useState("N");
  const [eventID, setEventID] = useState("");

  const timezoneOptions = [
    { value: "UTC+0", label: "UTC+0 (London, Lisbon)" },
    { value: "UTC+1", label: "UTC+1 (Berlin, Paris)" },
    { value: "UTC-1", label: "UTC-1 (Azores)" },
    { value: "UTC+2", label: "UTC+2 (Athens, Cairo)" },
    { value: "UTC-2", label: "UTC-2 (Fernando de Noronha)" },
    { value: "UTC+3", label: "UTC+3 (Moscow, Istanbul)" },
    { value: "UTC-3", label: "UTC-3 (Buenos Aires, São Paulo)" },
    { value: "UTC+4", label: "UTC+4 (Dubai, Baku)" },
    { value: "UTC-4", label: "UTC-4 (New York, Toronto)" },
    { value: "UTC+5", label: "UTC+5 (Karachi, Tashkent)" },
    { value: "UTC-5", label: "UTC-5 (Chicago, Mexico City)" },
    { value: "UTC+5:30", label: "UTC+5:30 (Mumbai, New Delhi)" },
    { value: "UTC+6", label: "UTC+6 (Dhaka, Almaty)" },
    { value: "UTC-6", label: "UTC-6 (Denver, El Salvador)" },
    { value: "UTC+7", label: "UTC+7 (Jakarta, Bangkok)" },
    { value: "UTC-7", label: "UTC-7 (Phoenix, Los Angeles)" },
    { value: "UTC+8", label: "UTC+8 (Singapore, Beijing)" },
    { value: "UTC-8", label: "UTC-8 (San Francisco, Vancouver)" },
    { value: "UTC+9", label: "UTC+9 (Tokyo, Seoul)" },
    { value: "UTC-9", label: "UTC-9 (Anchorage)" },
    { value: "UTC+10", label: "UTC+10 (Sydney, Melbourne)" },
    { value: "UTC-10", label: "UTC-10 (Hawaii)" },
    { value: "UTC+11", label: "UTC+11 (Solomon Islands)" },
    { value: "UTC-11", label: "UTC-11 (Midway Island)" },
    { value: "UTC+12", label: "UTC+12 (Auckland, Fiji)" },
    { value: "UTC-12", label: "UTC-12 (Baker Island)" },
  ];

  const handleTimezoneChange = (event: SelectChangeEvent) => {
    setSelectedTimezone(event.target.value);
  };

  // Handler to update field data
  const handleFieldChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, checked, type } = event.target;
    const newFields = [...fields];
    newFields[index] = {
      ...newFields[index],
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    };
    setFields(newFields);
  };

  // Handler to add a new field
  const handleAddField = () => {
    setFields([...fields, { field_name: "", is_required: 0 }]);
  };

  // Handler to remove a field
  const handleRemoveField = (index: number) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  // console.log(categories, "categories");
  // // Handler to submit the form
  // const handleSubmit = () => {
  //   const payload = {
  //     custom_fields: fields,
  //   };
  //   console.log("Payload:", JSON.stringify(payload));
  //   // Make an API call to send the payload to the backend
  //   // Example: axios.post('/api/endpoint', payload);
  // };
  const [tickets, setTickets] = useState<Ticket[]>([
    {
      id: 1,
      name: "",
      description: "",
      price: 1,
      quantity: 1,
      ticketAvailabilityTime: "",
      ticketAvailabilityDate: "",
      ticketAvailabilityEndTime: "",
      ticketAvailabilityEndDate: "",
      otherTicketName: "",
      allowCashPayment: false,
      max_cash_payments: 0,
    },
  ]);

  const [staffMember, setStaffMember] = useState<StaffMember[]>([]);
  const [isDisplayStaff, setIsSetDisplayStaff] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [filteredResults, setFilteredResults] = useState([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any>({});
  const [allowCashPayment, setAllowCashPayment] = useState<boolean>(false);
  const [maxCashPayment, setMaxCashPayment] = useState<number>(0);

  const API_KEY = process.env.REACT_APP_DEV_GOOGLE_MAPS_API_KEY ?? "";

  const handleAddTicket = () => {
    const newId = tickets.length > 0 ? tickets[tickets.length - 1].id + 1 : 1;
    setTickets([
      ...tickets,
      {
        id: newId,
        name: "",
        description: "",
        price: 1,
        quantity: 1,
        ticketAvailabilityTime: "",
        ticketAvailabilityDate: "",
        ticketAvailabilityEndTime: "",
        ticketAvailabilityEndDate: "",
        otherTicketName: "",
        isNewTicket: true,
        allowCashPayment: false,
        max_cash_payments: 0,
      },
    ]);
  };

  const handleDeleteTicket = (id: number) => {
    setTickets(tickets.filter((ticket) => ticket.id !== id));
  };

  const handleDeleteStaff = (mobileNumber: string) => {
    setStaffMember(
      staffMember.filter((staff) => staff.mobileNumber !== mobileNumber)
    );
  };

  const handleAddStaff = () => {
    setStaffMember([...staffMember, { mobileNumber: "" }]);
  };

  const handleStaffChange = (mobileNumber: string) => {};

  function escapeQuotes(input: string): string {
    // Escape single quotes and double quotes by adding a backslash in front of them
    return input.replace(/'/g, "\\'").replace(/"/g, '\\"');
  }

  const handleTicketChange = (
    id: number,
    field: keyof Ticket,
    value: string | number | boolean
  ) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) => {
        if (ticket.id === id) {
          if (field === "name") {
            const ticketName = String(value);
            if (ticketName === "Free") {
              return {
                ...ticket,
                name: ticketName,
                price: 0,
              } as Ticket;
            }
            return {
              ...ticket,
              name: ticketName,
              price: ticket.name === "Free" ? 1 : ticket.price,
            } as Ticket;
          }

          if (field === "price") {
            if (ticket.name === "Free") {
              return {
                ...ticket,
                price: 0,
              };
            }
            return {
              ...ticket,
              price: typeof value === "number" ? value : ticket.price,
            };
          }

          if (field === "allowCashPayment") {
            const allowCash = Boolean(value);
            return {
              ...ticket,
              allowCashPayment: allowCash,
              max_cash_payments: allowCash ? ticket.max_cash_payments : 0,
            };
          }

          return {
            ...ticket,
            [field]: value,
          } as Ticket;
        }
        return ticket;
      })
    );
  };

  // useEffect(() => {
  //   console.log("Selected Categories:", selectedCategories);
  // }, [selectedCategories]);

  const allValuesFilled = () => {
    if (
      !eventName ||
      !eventCurrency ||
      // {/* !description ||*/}
      !location ||
      !eventDate ||
      !eventEndDate ||
      !eventStartTime ||
      !eventEndTime ||
      !timezoneData ||
      !visibility ||
      !selectedCategories ||
      ((selectedCategories?.map((x) => x.category_name).includes("Virtual") ||
        selectedCategories?.map((x) => x.category_name).includes("Hybrid")) &&
        !meetingLink)
    )
      return false;
    for (const ticket of tickets) {
      if (
        !ticket.name ||
        // {/*!ticket.description || */}
        !(ticket.price >= 0) ||
        ticket.quantity <= 0 ||
        (ticket.name === "Custom" && !ticket.otherTicketName) ||
        (ticket.name === "Earlybird" &&
          (!ticket.ticketAvailabilityDate ||
            !ticket.ticketAvailabilityEndDate ||
            !ticket.ticketAvailabilityTime ||
            !ticket.ticketAvailabilityEndTime))
      ) {
        return false;
      }
    }
    //
    for (const field of fields) {
      if (!field.field_name) {
        return false;
      }
    }
    return true;
  };

  function formatDateTime(timestamp: number): string {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getFormattedNowDateTime = (): string => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSave = async (
    saveAsDraft?: boolean,
    saving2?: boolean,
    previewButtonHit?: boolean
  ) => {
    if (saving2 ?? saving) {
      return;
    }
    setSaving(true);
    if (!allValuesFilled() && !saveAsDraft) {
      toast("Please fill in all fields before saving.", { type: "error" });
      setSaving(false);

      return;
    }

    const eventFormData = new FormData();
    eventFormData.append("event_name", escapeQuotes(eventName));
    eventFormData.append(
      "image_sliders_id_delete",
      JSON.stringify(imageIdsDelete)
    );
    eventFormData.append(
      "event_date",
      `${eventDate} ${eventStartTime}${
        eventStartTime.split(":").length > 2 ? "" : ":00"
      }`
    );
    eventFormData.append("currency", eventCurrency);
    eventFormData.append(
      "end_date",
      `${eventEndDate} ${eventEndTime}${
        eventEndTime.split(":").length > 2 ? "" : ":00"
      }`
    );
    eventFormData.append("event_description", escapeQuotes(description));
    eventFormData.append("venue", escapeQuotes(location));
    eventFormData.append("visibility", visibility);
    eventFormData.append("event_timezone", selectedTimezone);
    eventFormData.append(
      "allow_waitlist",
      selectedWaitlistValue == "Y" ? "1" : "0"
    );
    eventFormData.append(
      "categories",
      JSON.stringify(selectedCategories.map((x) => x.id))
    );
    eventFormData.append("allow_cash_payment", "1");

    // if(saveAsDraft){
    // eventFormData.append("event_show_date", convertDateFormat(eventDate));
    eventFormData.append("event_show_date", formatDateTime(Date.now()));

    // }
    if (fields.length > 0) {
      eventFormData.append("custom_fields", JSON.stringify(fields));
    }

    if (saveAsDraft) {
      eventFormData.append("display_status", "draft");
    }
    if (
      (selectedCategories?.map((x) => x.category_name).includes("Virtual") ||
        selectedCategories?.map((x) => x.category_name).includes("Hybrid")) &&
      meetingLink
    ) {
      eventFormData.append("meeting_link", escapeQuotes(meetingLink));
    }

    if (
      eventImage.filter(
        (x) =>
          ![...(eventDetails?.image_sliders ?? [])]
            .map((img2: any) => img2.image_link)
            .includes(x)
      ).length >= 1
    ) {
      if (
        eventImage.filter(
          (x) =>
            ![...(eventDetails?.image_sliders ?? [])]
              .map((img2: any) => img2.image_link)
              .includes(x)
        ).length > 0
      ) {
        let blobList = await Promise.all(
          eventImage
            .filter(
              (x) =>
                ![...(eventDetails?.image_sliders ?? [])]
                  .map((img2: any) => img2.image_link)
                  .includes(x)
            )
            .map(async (img) => {
              const response = await fetch(img);
              return response.blob();
            })
        );

        blobList.forEach((blob) => {
          eventFormData.append(
            "slider_images",
            blob,
            `${Date.now()}.${blob.type.split("/")[1]}`
          );
        });
      }

      const response = await fetch(
        eventImage.filter(
          (x) =>
            ![...(eventDetails?.image_sliders ?? [])]
              .map((img2: any) => img2.image_link)
              .includes(x)
        )[0]
      );
      const blob = await response.blob();
      eventFormData.append(
        "poster",
        blob,
        `${Date.now()}.${blob.type.split("/")[1]}`
      );
    } else {
      if (imageIdsDelete.length > 0) {
        const data = eventDetails?.image_sliders.find(
          (x: any) => !imageIdsDelete.includes(x.id)
        );
        eventFormData.append("poster", `${data.image_link}`);
      }
    }
    // else {
    //   eventFormData.append("poster", eventDetails?.poster ?? "");
    // }

    if (!isDraft) {
      // if (!saveAsDraft || !isPublished) {
      if (!saveAsDraft && !isPublished) {
        const createGroupRes = await apiPostRequest(
          "/group/add",
          {
            group_name: escapeQuotes(eventName),
            description: `This is the group for the ${escapeQuotes(
              eventName
            )} event`,
            visibility: visibility,
          },
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (createGroupRes?.code === "00") {
          eventFormData.append("group_id", createGroupRes.data.id.toString());
        } else {
          toast(createGroupRes.msg ?? "Failed", {
            type: ErrorSeverityEnum.error,
          });

          return;
        }
      }

      if (isPublished) {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get("id");

        if (id) {
          const editPublishedTicket = await apiPutRequest(
            `/events/update/${id}`,
            eventFormData,
            {
              auth: userDetails.access_token,
              content_type: ContentTypeEnum.MULTIPART_FORM,
            }
          );

          if (editPublishedTicket?.code == "00") {
            toast(editPublishedTicket.msg ?? "Event Edited", {
              type: ErrorSeverityEnum.success,
            });
          } else {
            toast(errorMessage, {
              type: ErrorSeverityEnum.error,
            });
          }

          for (var tic of tickets) {
            const ticketPayload = {
              ticket_name: escapeQuotes(
                tic.name === "Custom" ? tic.otherTicketName : tic.name
              ),
              description: escapeQuotes(tic.description),
              price: tic.price,
              quantity: tic.quantity,
              currency: eventCurrency,
              event_id: id,
              ticket_sales_start_date:
                tic.ticketAvailabilityDate && tic.ticketAvailabilityTime
                  ? `${tic.ticketAvailabilityDate} ${tic.ticketAvailabilityTime}`
                  : getFormattedNowDateTime(),
              ticket_sales_end_date:
                tic.ticketAvailabilityEndDate && tic.ticketAvailabilityEndTime
                  ? `${tic.ticketAvailabilityEndDate} ${tic.ticketAvailabilityEndTime}`
                  : `${eventEndDate} ${eventEndTime}`,
              max_cash_payments: tic.allowCashPayment
                ? tic.max_cash_payments || 0
                : 0,
            };

            const createTicketRes = await (tic.isNewTicket
              ? apiPostRequest
              : apiPutRequest)(
              `/ticket/${tic.isNewTicket ? "add" : `update/${tic.id}`}`,
              ticketPayload,
              {
                auth: userDetails.access_token,
                content_type: ContentTypeEnum.APPLICATION_JSON,
              }
            );

            // toast(createTicketRes.msg ?? "Ticket Added", {
            //   type: ErrorSeverityEnum.success,
            // });
          }
          navigate("/event-admin");
        }
      } else {
        const createEventRes = await apiPostRequest(
          "/events/add",
          eventFormData,
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.MULTIPART_FORM,
          }
        );

        if (createEventRes?.code === "00") {
          toast(createEventRes.msg ?? "Event Created", {
            type: ErrorSeverityEnum.success,
          });

          tickets.forEach(async (tic) => {
            const ticketPayload: any = {
              ticket_name: escapeQuotes(
                tic.name === "Custom" ? tic.otherTicketName : tic.name
              ),
              description: escapeQuotes(tic.description),
              price: tic.price,
              quantity: tic.quantity,
              currency: eventCurrency,
              event_id: createEventRes.data.id,
              ticket_sales_end_date: `${eventEndDate} ${eventEndTime}${
                eventEndTime.split(":").length > 2 ? "" : ":00"
              }`,
              ticket_sales_start_date: getFormattedNowDateTime(),
              max_cash_payments: tic.allowCashPayment
                ? tic.max_cash_payments || 0
                : 0,
            };

            if (tic.name === "Earlybird") {
              ticketPayload.ticket_sales_start_date = `${
                tic.ticketAvailabilityDate
              } ${tic.ticketAvailabilityTime}${
                tic.ticketAvailabilityTime.split(":").length > 2 ? "" : ":00"
              }`;
              ticketPayload.ticket_sales_end_date = `${
                tic.ticketAvailabilityEndDate
              } ${tic.ticketAvailabilityEndTime}${
                tic.ticketAvailabilityEndTime.split(":").length > 2 ? "" : ":00"
              }`;
            }

            const createTicketRes = await apiPostRequest(
              "/ticket/add",
              ticketPayload,
              {
                auth: userDetails.access_token,
                content_type: ContentTypeEnum.APPLICATION_JSON,
              }
            );

            // toast(createTicketRes.msg ?? "Ticket Added", {
            //   type: ErrorSeverityEnum.success,
            // });
          });

          //     tickets.forEach(async (ticket) => {
          //       const ticketFormData = new FormData();

          // ticketFormData.append(`ticket_name`, ticket.name);
          // ticketFormData.append(`description`, ticket.description);
          // ticketFormData.append(`price`, ticket.price.toString());
          // ticketFormData.append(`quantity`, ticket.quantity.toString());
          // ticketFormData.append(`event_id`, createEventRes.data.id.toString());
          //   // if (ticket.image) {
          //   //   const ticketBlob = new Blob([ticket.image], { type: "image/png" });
          //   //   ticketFormData.append(`image`, ticketBlob);
          //   // }
          //       const createTicketRes = await apiPostRequest(
          //         "/ticket/add",
          //         ticketFormData,
          //         {
          //           auth: userDetails.access_token,
          //           content_type: ContentTypeEnum.MULTIPART_FORM,
          //         }
          //       );

          //       toast(createTicketRes.msg ?? "Ticket Added", {
          //         type: ErrorSeverityEnum.success,
          //       });
          //     });
        } else {
          toast(errorMessage, {
            type: ErrorSeverityEnum.error,
          });
        }
      }

      // } else {
      // toast(createGroupRes.msg ?? "Failed", {
      //   type: ErrorSeverityEnum.error,
      // });
      // }
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      const id = searchParams.get("id");
      if (id) {
        // eventFormData.append("event_show_date", convertDateFormat(eventDate));
        eventFormData.append("event_show_date", formatDateTime(Date.now()));
        eventFormData.append("display_status", "published");

        const res = await apiPutRequest(
          `${urls.events}update/${id}`,
          eventFormData,
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.MULTIPART_FORM,
          }
        );
        if (res?.code == "00") {
          tickets.forEach(async (tic) => {
            const createTicketRes = await (tic.isNewTicket
              ? apiPostRequest
              : apiPutRequest)(
              `/ticket/${tic.isNewTicket ? "add" : `update/${tic.id}`}`,
              {
                ticket_name: escapeQuotes(
                  tic.name === "Custom" ? tic.otherTicketName : tic.name
                ),
                description: escapeQuotes(tic.description),
                price: tic.price,
                quantity: tic.quantity,
                currency: eventCurrency,
                event_id: id,
                ...(tic.name == "Earlybird"
                  ? {
                      ticket_sales_start_date: `${tic.ticketAvailabilityDate} ${tic.ticketAvailabilityTime}`,
                      ticket_sales_end_date: `${tic.ticketAvailabilityEndDate} ${tic.ticketAvailabilityEndTime}`,
                    }
                  : {
                      ticket_sales_start_date: getFormattedNowDateTime(),
                      ticket_sales_end_date: `${eventEndDate} ${eventEndTime}${
                        eventEndTime.split(":").length > 2 ? "" : ":00"
                      }`,
                    }),
                max_cash_payments: allowCashPayment
                  ? tic.max_cash_payments || 0
                  : 0,
              },
              {
                auth: userDetails.access_token,
                content_type: ContentTypeEnum.APPLICATION_JSON,
              }
            );

            // toast(createTicketRes.msg ?? "Ticket Added", {
            //   type: ErrorSeverityEnum.success,
            // });
          });
          toast(res.msg ?? "Event Edited", {
            type: ErrorSeverityEnum.success,
          });

          // console.log("THIS IS DOUBLE RES.DATA:::::", res);
          if (previewButtonHit) {
            navigate(`/e/${res.data?.purl}`);
          } else {
            navigate("/event-admin");
          }
        } else {
          toast(errorMessage, {
            type: ErrorSeverityEnum.error,
          });
        }
      }
    }
    if (!previewButtonHit) {
      navigate("/event-admin");
    }
    setSaving(false);
  };
  const handleDeleteEvent = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    if (id) {
      const res = await apiDeleteRequest(`${urls.events}${id}`, {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });
      if (res?.code === "00") {
        toast(res.msg ?? "Event Deleted", {
          type: ErrorSeverityEnum.success,
        });
        navigate("/event-admin");
      } else {
        toast(errorMessage, {
          type: ErrorSeverityEnum.error,
        });
      }
    }
  };
  const populateTickets = (ticketArray: any[]) => {
    const mappedTickets = ticketArray.map((ticket) => {
      return {
        id: ticket.id,
        name: ticketOptions.every(
          (opt) =>
            `${opt}`.toLowerCase() !==
            `${
              ticket.ticket_name.includes("-")
                ? `${ticket.ticket_name.split(" - ")[0]}`.trim()
                : ticket.ticket_name
            }`.toLowerCase()
        )
          ? "Custom"
          : ticket.ticket_name.includes("-")
          ? `${ticket.ticket_name.split(" - ")[0]}`.trim()
          : ticket.ticket_name,
        otherTicketName: ticket.ticket_name.includes("-")
          ? `${ticket.ticket_name.split(" - ")[0]}`.trim()
          : ticket.ticket_name,
        description: ticket.description,
        price: ticket.price,
        quantity: ticket.quantity,
        ticketAvailabilityDate: new Date(
          ticket.ticket_sales_start_date
        ).toLocaleDateString("en-CA"),
        ticketAvailabilityTime: new Date(
          ticket.ticket_sales_start_date
        ).toLocaleTimeString("en-GB"),
        ticketAvailabilityEndDate: new Date(
          ticket.ticket_sales_end_date
        ).toLocaleDateString("en-CA"),
        ticketAvailabilityEndTime: new Date(
          ticket.ticket_sales_end_date
        ).toLocaleTimeString("en-GB"),
        allowCashPayment: ticket.max_cash_payment_allowed > 0,
        max_cash_payments: ticket.max_cash_payment_allowed,
      };
    });

    setTickets(mappedTickets);

    // Set maxCashPayment based on the first ticket with max_cash_payment_allowed
    const ticketWithMaxCash = ticketArray.find(
      (ticket) => ticket.max_cash_payment_allowed !== null
    );
    if (ticketWithMaxCash) {
      setMaxCashPayment(ticketWithMaxCash.max_cash_payment_allowed);
    }
  };
  function convertDateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const populateFields = (draftEvent: any) => {
    // Handle the timezone thing...
    let [continent, city] = draftEvent.event_timezone.split(", ");
    const initialSelectedCategories = draftEvent.categories.map(
      (category: any) => ({
        id: category.id,
        sub_category_name: category.sub_category_name,
        category_id: category.category_id,
        date_added: category.date_added,
        image_link: category.image_link,
        status: category.status,
      })
    );

    setSelectedCategories(initialSelectedCategories);
    setEventName(draftEvent.event_name);
    // console.log("THIS IS DRAFT EVENT:::::", draftEvent);
    setEventImage(draftEvent.image_sliders.map((img: any) => img.image_link));
    setDescription(draftEvent.description);
    setLocation(draftEvent.venue);
    setEventCurrency(draftEvent.currency);
    setMeetingLink(draftEvent.meeting_link);
    setEventDate(convertDateFormat(draftEvent.event_date));
    setEventEndDate(convertDateFormat(draftEvent.end_date));
    setEventStartTime(
      new Date(draftEvent.event_date).toLocaleTimeString("en-GB")
    );
    setEventEndTime(new Date(draftEvent.end_date).toLocaleTimeString("en-GB"));
    // setTicketAvailabilityDate(draftEvent.ticketAvailabilityDate);
    // setTicketAvailabilityTime(draftEvent.ticketAvailabilityTime);
    setVisibility(draftEvent.visibility);
    setContinent(continent);
    setCity(city);
    populateTickets(draftEvent.tickets);
    setFields(draftEvent.custom_fields);
    setSelectedTimezone(draftEvent.event_timezone);
    setAllowCashPayment(draftEvent.allow_cash_payments === 1);
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    const categoriesRes = await apiGetRequest(urls.categories);
    if (storedData) {
      try {
        if (categoriesRes?.data != null) {
          setCategories(categoriesRes?.data);
        }

        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const currenciesRes = await apiGetRequest(urls.getCurrencies);

        if (currenciesRes?.code == "00") {
          // console.log("Currencies::::", currenciesRes.data);

          // Create a copy of the array and then sort it
          const sortedCurrencies = sortCurrenciesByValue([
            ...currenciesRes.data,
          ]);

          // console.log("Sorted currencies::::", sortedCurrencies);

          // Update the state with the new sorted array
          setCurrencies(sortedCurrencies);
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    if (id && status === "draft") {
      setIsDraft(true);
      const res = await apiGetRequest(`${urls.events}${id}`);

      if (res?.data != null) {
        setEventID(id);
        setEventDetails(res.data);
        populateFields(res?.data);
      }
    } else if (id && status === "published") {
      setIsPublished(true);
      setIsSetDisplayStaff(true);
      const res = await apiGetRequest(`${urls.events}${id}`);

      if (res?.data != null) {
        setEventDetails(res.data);
        populateFields(res?.data);
      }
    }
  };

  const handleCheckboxChange = (categoryName: string) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryName)
        ? prevSelected.filter((name) => name !== categoryName)
        : [...prevSelected, categoryName]
    );
  };

  const fetchTimezones = async () => {
    try {
      const response = await axios.get(
        `https://api.timezonedb.com/v2.1/list-time-zone`,
        {
          params: {
            key: "S9Q29URR3NOM",
            format: "json",
          },
        }
      );

      if (!response.data.zones) {
        throw new Error("Expected an array of timezones");
      }

      const groupedData = response.data.zones.reduce(
        (acc: TimezoneData, timezone: Timezone) => {
          const [continent, city] = timezone.zoneName.split("/");
          if (!acc[continent]) {
            acc[continent] = [];
          }
          if (city) {
            acc[continent].push(city);
          }
          return acc;
        },
        {}
      );

      setTimezoneData(groupedData);
    } catch (error) {
      console.error("Error fetching timezone data:", error);
    }
  };

  useEffect(() => {
    fetchTimezones();
  }, []);

  const handlePlaceSelect = (place: google.maps.places.PlaceResult | null) => {
    if (place && place.formatted_address) {
      setLocation(place.formatted_address);
    } else if (place === null) {
      setLocation("");
    }
  };

  const handleLocationChange = (value: string) => {
    setLocation(value);
  };

  const handleContinentChange = (event: SelectChangeEvent) => {
    setContinent(event.target.value);
    setCity("");
  };

  const handleChange = async (value: string) => {
    setLocation(value);
    if (value) {
      const results = await fetchAddresses(value);
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  const fetchAddresses = async (query: any) => {
    try {
      // const response = await fetch(
      //   `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&key=${process.env.REACT_APP_DEV_GOOGLE_MAPS_API_KEY}&types=geocode`,
      //   { method: "GET", mode: "no-cors" }
      // );
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
        {
          params: {
            input: query,
            key: process.env.REACT_APP_DEV_GOOGLE_MAPS_API_KEY,
            types: "geocode",
          },
        }
      );

      return response.data.predictions.map(
        (prediction: { description: any }) => prediction.description
      );
    } catch (error) {
      console.error("Error fetching addresses:", error);
      return [];
    }
  };

  const handleCityChange = (event: SelectChangeEvent) => {
    setCity(event.target.value);
  };

  const handleSelect = (address: any) => {
    setLocation(address);
    setFilteredResults([]);
    // setError(false);
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  const handleShowPreview = async () => {
    const eventFormData = new FormData();
    eventFormData.append("event_name", eventName);
    eventFormData.append(
      "image_sliders_id_delete",
      JSON.stringify(imageIdsDelete)
    );
    eventFormData.append(
      "event_date",
      `${eventDate} ${eventStartTime}${
        eventStartTime.split(":").length > 2 ? "" : ":00"
      }`
    );
    eventFormData.append("currency", eventCurrency);
    eventFormData.append(
      "end_date",
      `${eventEndDate} ${eventEndTime}${
        eventEndTime.split(":").length > 2 ? "" : ":00"
      }`
    );
    eventFormData.append("event_description", description);
    eventFormData.append("venue", location);
    eventFormData.append("visibility", visibility);
    eventFormData.append("event_timezone", selectedTimezone);
    eventFormData.append(
      "categories",
      JSON.stringify(selectedCategories.map((x) => x.id))
    );

    // if(saveAsDraft){
    // eventFormData.append("event_show_date", convertDateFormat(eventDate));
    eventFormData.append("event_show_date", formatDateTime(Date.now()));

    // }
    if (fields.length > 0) {
      eventFormData.append("custom_fields", JSON.stringify(fields));
    }

    eventFormData.append("display_status", "draft");
    if (
      (selectedCategories?.map((x) => x.category_name).includes("Virtual") ||
        selectedCategories?.map((x) => x.category_name).includes("Hybrid")) &&
      meetingLink
    ) {
      eventFormData.append("meeting_link", meetingLink);
    }

    if (
      eventImage.filter(
        (x) =>
          ![...(eventDetails?.image_sliders ?? [])]
            .map((img2: any) => img2.image_link)
            .includes(x)
      ).length >= 1
    ) {
      if (
        eventImage.filter(
          (x) =>
            ![...(eventDetails?.image_sliders ?? [])]
              .map((img2: any) => img2.image_link)
              .includes(x)
        ).length > 0
      ) {
        let blobList = await Promise.all(
          eventImage
            .filter(
              (x) =>
                ![...(eventDetails?.image_sliders ?? [])]
                  .map((img2: any) => img2.image_link)
                  .includes(x)
            )
            .map(async (img) => {
              const response = await fetch(img);
              return response.blob();
            })
        );

        blobList.forEach((blob) => {
          eventFormData.append(
            "slider_images",
            blob,
            `${Date.now()}.${blob.type.split("/")[1]}`
          );
        });
      }

      const response = await fetch(
        eventImage.filter(
          (x) =>
            ![...(eventDetails?.image_sliders ?? [])]
              .map((img2: any) => img2.image_link)
              .includes(x)
        )[0]
      );
      const blob = await response.blob();
      eventFormData.append(
        "poster",
        blob,
        `${Date.now()}.${blob.type.split("/")[1]}`
      );
    } else {
      if (imageIdsDelete.length > 0) {
        const data = eventDetails?.image_sliders.find(
          (x: any) => !imageIdsDelete.includes(x.id)
        );
        eventFormData.append("poster", `${data.image_link}`);
      }
    }

    var object: any = {};
    eventFormData.forEach((value, key) => (object[key] = value));
    object["tickets"] = tickets.map((tic) => ({
      ticket_name: tic.name === "Custom" ? tic.otherTicketName : tic.name,
      description: tic.description,
      price: tic.price,
      quantity: tic.quantity,
      currency: eventCurrency,
      ticket_sales_start_date: `${tic.ticketAvailabilityDate} ${
        tic.ticketAvailabilityTime
      }${tic.ticketAvailabilityTime.split(":").length > 2 ? "" : ":00"}`,
      ticket_sales_end_date: `${tic.ticketAvailabilityEndDate} ${
        tic.ticketAvailabilityEndTime
      }${tic.ticketAvailabilityEndTime.split(":").length > 2 ? "" : ":00"}`,
    }));

    // console.log("The OBJECT.......", object);
    // console.log("objjjjjjjj::::::", object);
    setPreviewData(object);
    setShowPreview(true);
    window.scrollTo(0, 0);
  };

  const isPreviewDisabled = () => {
    return tickets.some(
      (ticket) =>
        ticket.allowCashPayment && ticket.max_cash_payments > ticket.quantity
    );
  };

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      {showPreview && (
        <CreateNewEventPreview
          eventDetails={previewData}
          closePreview={() => setShowPreview(false)}
          images={eventImage}
          handleSave={() => {
            setSaving(false);
            handleSave(undefined, false);
          }}
        />
      )}

      <Helmet>
        <title>Create Event</title>
      </Helmet>

      {!showPreview && (
        <CreateEventWrapper>
          <Row mainAxisAlignment="space-between" crossAxisAlignment="end">
            <Column>
              <HeaderGreenTopBar />
              <HeaderText>{isDraft ? "EDIT DRAFT" : "NEW EVENT"}</HeaderText>
              {/* Request USSD */}
            </Column>
            {isDraft && (
              <Row
                crossAxisAlignment="center"
                spacing={10}
                onClick={handleDeleteEvent}
              >
                <HeaderText style={{ fontSize: "16px" }}>
                  Delete Event
                </HeaderText>
                <img
                  style={{ width: "18px", height: "18px", marginRight: "5px" }}
                  src={BinSvg}
                />
              </Row>
            )}
          </Row>

          <InputBodyWrapper>
            {eventImage.length > 0 && (
              <BodyText2 style={{ display: "flex", justifyContent: "center" }}>
                You can add more images. The first image would be the poster
                image
              </BodyText2>
            )}
            <Row
              spacing={10}
              mainAxisAlignment="center"
              crossAxisAlignment="center"
              style={{ overflowX: "auto", width: "100%", height: "220px" }}
            >
              {eventImage.length > 0 && (
                <>
                  {eventImage.map((img, index) => (
                    <Box
                      mt={2}
                      sx={{ width: "150px", height: "150px" }}
                      flexDirection={"row"}
                    >
                      <VaryingWidthImage
                        key={index}
                        blurImageUrl={img}
                        imageUrl={img}
                      />
                      <DeleteIcon
                        sx={{
                          width: "150px",
                          height: "25px",
                          color: "red",
                          margin: "0 auto",
                        }}
                        onClick={() => {
                          const data = eventDetails?.image_sliders.find(
                            (x: any) => x.image_link == img
                          );
                          if (data?.id) {
                            setImageIdsDelete([...imageIdsDelete, data.id]);
                          }
                          setEventImage(
                            eventImage.filter((x, ind) => ind !== index)
                          );
                        }}
                      />
                    </Box>
                  ))}
                </>
              )}
            </Row>
            <Row spacing={20}>
              <InputField
                onChange={(e) => setEventName(e)}
                label="Name of the Event"
                value={eventName}
                required={true}
              />
              <ImageUpload
                onImageUpload={(image: string) => {
                  setEventImage([...eventImage, image]);
                }}
                // currentImage={eventImage}
              />
            </Row>
            <InputField
              onChange={(e) => setDescription(e)}
              label="Event Description"
              multiline
              value={description}
              required={false}
            />

            <PlaceAutocomplete
              onPlaceSelect={handlePlaceSelect}
              value={location}
              onChange={handleLocationChange}
            />

            <Grid item xs={6} lg={2}>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ marginTop: "16px" }}
              >
                <InputLabel id="ticket-currency">
                  Currency <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  labelId="ticket-currency-label"
                  value={eventCurrency}
                  sx={{
                    borderRadius: "100rem",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "100rem",
                    },
                    "& .MuiSelect-select": {
                      borderRadius: "100rem",
                    },
                  }}
                  onChange={(e) => setEventCurrency(e.target.value)}
                  label="Ticket Currency"
                >
                  {currencies?.map((option: any) => (
                    <MenuItem
                      key={option?.currency_name}
                      value={option?.currency_symbol}
                    >
                      {option?.currency_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Row spacing={30}>
              <div
                style={{ marginTop: "16px", width: "100%", maxWidth: "300px" }}
              >
                <DropdownComponent
                  label="Event Visibility"
                  options={[
                    { value: "private", label: "Private" },
                    { value: "public", label: "Public" },
                  ]}
                  selectedValue={visibility}
                  onChange={(e) =>
                    setVisibility(e.target.value as "public" | "private")
                  }
                />
              </div>
              <CategoriesMultiSelectDropdown
                setCategories={setSelectedCategories}
                categories={categories}
                initialSelectedCategories={selectedCategories}
              />
              {/* <div
            style={{ marginTop: "16px", width: "50%", marginBottom: "10px" }}
          >
            <label style={{ fontWeight: "bold" }}>Event Category</label>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {categories.map((category) => (
                <div
                  key={category.id}
                  style={{
                    marginTop: "10px",
                    marginRight: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedCategories.includes(
                      category.category_name
                    )}
                    onChange={() =>
                      handleCheckboxChange(category.category_name)
                    }
                  />
                  <span>{category.category_name}</span>
                </div>
              ))}
            </div>
          </div> */}
            </Row>
            <Grid container spacing={3} sx={{ flexWrap: "wrap" }}>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Event Date <span style={{ color: "red" }}>*</span>
                </BodyText2>
                <InputField
                  onChange={(e) => setEventDate(e)}
                  label=""
                  type="date"
                  value={eventDate}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Event End Date <span style={{ color: "red" }}>*</span>
                </BodyText2>
                <InputField
                  onChange={(e) => setEventEndDate(e)}
                  label=""
                  type="date"
                  value={eventEndDate}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Event Start Time <span style={{ color: "red" }}>*</span>
                </BodyText2>
                <InputField
                  onChange={(e) => setEventStartTime(e)}
                  label=""
                  type="time"
                  value={eventStartTime}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Event End Time <span style={{ color: "red" }}>*</span>
                </BodyText2>
                <InputField
                  onChange={(e) => setEventEndTime(e)}
                  label=""
                  type="time"
                  value={eventEndTime}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Timezone
                </BodyText2>
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <DropdownComponent
                    selectedValue={selectedTimezone}
                    isLabelOption={true}
                    options={timezoneOptions}
                    onChange={(e: any) => handleTimezoneChange(e)}
                    label=""
                  />
                </div>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <BodyText2
                  style={{ fontSize: "10px", color: "#bbb", margin: "0" }}
                >
                  Waitlist Event
                </BodyText2>
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <DropdownComponent
                    selectedValue={selectedWaitlistValue}
                    isLabelOption={true}
                    options={[
                      { label: "On", value: "Y" },
                      { label: "Off", value: "N" },
                    ]}
                    onChange={(e: any) =>
                      setSelectedWaitlistValue(e.target.value)
                    }
                    label=""
                  />
                </div>
              </Grid> */}
            </Grid>

            <Row spacing={30}>
              {/* <Column>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Event Type
            </Typography>
            <TextField
              select
              value={eventType}
              onChange={handleEventTypeChange}
              fullWidth
            >
              <MenuItem value="Virtual">Virtual</MenuItem>
              <MenuItem value="In-person">In-person</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
            </TextField>
          </Column> */}

              {(selectedCategories
                ?.map((x) => x.category_name)
                .includes("Virtual") ||
                selectedCategories
                  ?.map((x) => x.category_name)
                  .includes("Hybrid")) && (
                <Column>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Meeting Link
                  </Typography>
                  <TextField
                    onChange={(e) => setMeetingLink(e.target.value)}
                    type="url"
                    value={meetingLink}
                    fullWidth
                  />
                </Column>
              )}
            </Row>
            {/* <Row spacing={30}>
          <Column style={{ width: "100%" }}>
            <BodyText2 style={{ fontSize: "10px", color: "#bbb", margin: "0" }}>
              Ticket Availability Date
            </BodyText2>
            <InputField
              onChange={(e) => setTicketAvailabilityDate(e)}
              label=""
              type="date"
              value={ticketAvailabilityDate}
            />
          </Column>
          <Column style={{ width: "100%" }}>
            <BodyText2 style={{ fontSize: "10px", color: "#bbb", margin: "0" }}>
              Ticket Availability Time
            </BodyText2>
            <InputField
              onChange={(e) => setTicketAvailabilityTime(e)}
              label=""
              type="time"
              value={ticketAvailabilityTime}
            />
          </Column>
        </Row> */}
            <Row
              mainAxisAlignment="space-between"
              crossAxisAlignment="end"
              sx={{ marginTop: "3rem" }}
            >
              <Column>
                <HeaderGreenTopBar />
                <HeaderText>CUSTOM FIELDS</HeaderText>
              </Column>

              <Button
                variant="outlined"
                startIcon={<AddCircle />}
                onClick={handleAddField}
              >
                Add Field
              </Button>
            </Row>
            <Box>
              {fields.map((field, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <TextField
                    label="Field Name"
                    name="field_name"
                    value={field.field_name}
                    onChange={(event: any) => handleFieldChange(index, event)}
                    sx={{ mr: 2 }}
                    required
                  />
                  {/* <InputField
            label="Field Name"
            value={ticket.price.toString()}
                  onChange={(e) => {
                    handleFieldChange(index, event);
                  }}
                /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_required"
                        checked={field.is_required === 1}
                        onChange={(event: any) =>
                          handleFieldChange(index, event)
                        }
                      />
                    }
                    label="Required"
                  />
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemoveField(index)}
                    disabled={fields.length === 1}
                  >
                    <RemoveCircle />
                  </IconButton>
                </Box>
              ))}
              {/* <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box> */}
            </Box>
            <Row
              mainAxisAlignment="space-between"
              crossAxisAlignment="end"
              sx={{ marginTop: "3rem" }}
            >
              <Column>
                <HeaderGreenTopBar />
                <HeaderText>TICKET TYPES AND QUANTITIES</HeaderText>
              </Column>

              <Row
                crossAxisAlignment="center"
                spacing={10}
                onClick={handleAddTicket}
                sx={{ ":hover": { cursor: "pointer" } }}
              >
                <HeaderText style={{ fontSize: "16px" }}>Add Ticket</HeaderText>
                <AddIcon />
              </Row>
            </Row>
            {tickets.map((ticket, index) => (
              <Row key={ticket.id} crossAxisAlignment="center" spacing={30}>
                {/* {ticket.image && (
              <Box mt={2} sx={{ width: "100px", height: "100px" }}>
                <VaryingWidthImage
                  blurImageUrl={ticket.image}
                  imageUrl={ticket.image}
                />
              </Box>
            )} */}

                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                <InputField
                  label="Ticket Name"
                  onChange={(e) => handleTicketChange(ticket.id, "name", e)}
                  value={ticket.name}
                />
              </Grid> */}
                  <Grid item xs={12} lg={ticket.name === "Custom" ? 2 : 3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: "16px" }}
                    >
                      <InputLabel id="ticket-name-label">
                        Ticket Type <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="ticket-name-label"
                        value={
                          ticket.name.includes("-")
                            ? ticket.name.split(" - ")[0]
                            : ticket.name
                        }
                        sx={{
                          borderRadius: "100rem",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "100rem",
                          },
                          "& .MuiSelect-select": {
                            borderRadius: "100rem",
                          },
                        }}
                        onChange={(e) =>
                          handleTicketChange(ticket.id, "name", e.target.value)
                        }
                        label="Ticket Type"
                      >
                        {ticketOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Row spacing={30}>
          <Column style={{ width: "100%" }}>
            <BodyText2 style={{ fontSize: "10px", color: "#bbb", margin: "0" }}>
              Ticket Availability Date
            </BodyText2>
            <InputField
              onChange={(e) => setTicketAvailabilityDate(e)}
              label=""
              type="date"
              value={ticketAvailabilityDate}
            />
          </Column>
          <Column style={{ width: "100%" }}>
            <BodyText2 style={{ fontSize: "10px", color: "#bbb", margin: "0" }}>
              Ticket Availability Time
            </BodyText2>
            <InputField
              onChange={(e) => setTicketAvailabilityTime(e)}
              label=""
              type="time"
              value={ticketAvailabilityTime}
            />
          </Column>
        </Row> */}
                  {ticket.name === "Custom" && (
                    <Grid item xs={12} lg={2.5}>
                      <InputField
                        label="Name of Ticket"
                        onChange={(e) =>
                          handleTicketChange(ticket.id, "otherTicketName", e)
                        }
                        required={true}
                        // multiline
                        value={ticket.otherTicketName}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={ticket.name === "Custom" ? 3.5 : 4.5}>
                    <InputField
                      label="Ticket Details"
                      onChange={(e) =>
                        handleTicketChange(ticket.id, "description", e)
                      }
                      // multiline
                      value={ticket.description}
                      required={false}
                    />
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <InputField
                      label="Ticket Price"
                      value={ticket.price.toString()}
                      onChange={(e) => {
                        const newPrice = parseInt(e);
                        if (!isNaN(newPrice) && newPrice >= 0) {
                          handleTicketChange(ticket.id, "price", newPrice);
                        } else {
                          handleTicketChange(ticket.id, "price", 0);
                        }
                      }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={6} lg={2}>
                    <InputField
                      label="Ticket Quantity"
                      value={ticket.quantity.toString()}
                      onChange={(e) => {
                        if (e === "") {
                          handleTicketChange(ticket.id, "quantity", 0);
                        } else {
                          const newQuantity = parseInt(e);
                          if (!isNaN(newQuantity) && newQuantity >= 0) {
                            handleTicketChange(
                              ticket.id,
                              "quantity",
                              newQuantity
                            );
                          }
                        }
                      }}
                      required={true}
                    />
                  </Grid>
                  {/* <Row spacing={30}> */}
                  {ticket.name !== "Free" && (
                    <>
                      <Grid item xs={6} lg={1.5}>
                        <Column style={{ width: "100%" }}>
                          <BodyText2
                            style={{
                              fontSize: "10px",
                              color: "#bbb",
                              margin: "0",
                            }}
                          >
                            Allow Cash Purchase{" "}
                          </BodyText2>
                          <Select
                            value={ticket.allowCashPayment ? "true" : "false"}
                            onChange={(e) =>
                              handleTicketChange(
                                ticket.id,
                                "allowCashPayment",
                                e.target.value === "true"
                              )
                            }
                            sx={{
                              borderRadius: "100rem",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "100rem",
                              },
                              "& .MuiSelect-select": {
                                borderRadius: "100rem",
                              },
                              width: "100%",
                              marginTop: "18px",
                            }}
                          >
                            <MenuItem value="false">No</MenuItem>
                            <MenuItem value="true">Yes</MenuItem>
                          </Select>
                        </Column>
                      </Grid>

                      {ticket.allowCashPayment && (
                        <Grid item xs={6} lg={1.5}>
                          <Column style={{ width: "100%" }}>
                            <BodyText2
                              style={{
                                fontSize: "10px",
                                color: "#bbb",
                                margin: "0",
                              }}
                            >
                              Cash Ticket Quantity{" "}
                              <span style={{ color: "red" }}>*</span>
                            </BodyText2>
                            <InputField
                              min={0}
                              max={ticket.quantity}
                              value={ticket.max_cash_payments}
                              onChange={(e) => {
                                const newValue = parseInt(e) || 0;
                                handleTicketChange(
                                  ticket.id,
                                  "max_cash_payments",
                                  newValue
                                );
                              }}
                            />
                          </Column>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* </Row> */}
                  {ticket.name === "Earlybird" && (
                    <>
                      <Grid item xs={6} lg={2}>
                        <Column style={{ width: "100%" }}>
                          <BodyText2
                            style={{
                              fontSize: "10px",
                              color: "#bbb",
                              margin: "0",
                            }}
                          >
                            Ticket Availability Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </BodyText2>
                          <InputField
                            onChange={(e) => {
                              handleTicketChange(
                                ticket.id,
                                "ticketAvailabilityDate",
                                e
                              );
                            }}
                            label=""
                            type="date"
                            value={ticket.ticketAvailabilityDate.toString()}
                          />
                        </Column>
                      </Grid>
                      <Grid item xs={6} lg={2}>
                        <Column style={{ width: "100%" }}>
                          <BodyText2
                            style={{
                              fontSize: "10px",
                              color: "#bbb",
                              margin: "0",
                            }}
                          >
                            Ticket Availability Time{" "}
                            <span style={{ color: "red" }}>*</span>
                          </BodyText2>
                          <InputField
                            onChange={(e) => {
                              handleTicketChange(
                                ticket.id,
                                "ticketAvailabilityTime",
                                e
                              );
                            }}
                            label=""
                            type="time"
                            value={ticket.ticketAvailabilityTime.toString()}
                          />
                        </Column>
                      </Grid>
                      <Grid item xs={6} lg={2}>
                        <Column style={{ width: "100%" }}>
                          <BodyText2
                            style={{
                              fontSize: "10px",
                              color: "#bbb",
                              margin: "0",
                            }}
                          >
                            Ticket Availability End Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </BodyText2>
                          <InputField
                            onChange={(e) => {
                              handleTicketChange(
                                ticket.id,
                                "ticketAvailabilityEndDate",
                                e
                              );
                            }}
                            label=""
                            type="date"
                            value={ticket.ticketAvailabilityEndDate.toString()}
                          />
                        </Column>
                      </Grid>
                      <Grid item xs={6} lg={2}>
                        <Column style={{ width: "100%" }}>
                          <BodyText2
                            style={{
                              fontSize: "10px",
                              color: "#bbb",
                              margin: "0",
                            }}
                          >
                            Ticket Availability End Time{" "}
                            <span style={{ color: "red" }}>*</span>
                          </BodyText2>
                          <InputField
                            onChange={(e) => {
                              handleTicketChange(
                                ticket.id,
                                "ticketAvailabilityEndTime",
                                e
                              );
                            }}
                            label=""
                            type="time"
                            value={ticket.ticketAvailabilityEndTime.toString()}
                          />
                        </Column>
                      </Grid>
                    </>
                  )}

                  {/* <Grid item xs={4}>
                <ImageUpload
                  onImageUpload={(image: string) => {
                    handleTicketChange(ticket.id, "image", image);
                  }}
                />
              </Grid> */}

                  {index + 1 !== tickets.length && (
                    <Divider sx={{ width: "100%", margin: "2rem 0" }} />
                  )}
                </Grid>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteTicket(ticket.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Row>
            ))}
          </InputBodyWrapper>

          <Row spacing={40} sx={{ marginBottom: "40rem" }}>
            {!isPublished ? (
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  borderColor: "#0CAF6D",
                  color: "#0CAF6D",
                }}
                // disabled={!allValuesFilled() }
                onClick={() => handleSave(true, false)}
              >
                {isDraft ? "Save Draft Edits" : "Save As Draft"}
              </Button>
            ) : (
              ""
            )}
            {isDraft ? (
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                onClick={async () => {
                  // await handleSave(true, false, true);
                  handleShowPreview();
                }}
              >
                Preview
              </Button>
            ) : isPublished ? (
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                onClick={() => {
                  setSaving(false);
                  handleSave(undefined, false);
                }}
              >
                Republish
              </Button>
            ) : (
              // <Button
              //   variant="contained"
              //   sx={{
              //     width: "100%",
              //     marginTop: "5rem",
              //     height: "48px",
              //     borderRadius: "100rem",
              //     backgroundColor: "#0CAF6D",
              //   }}
              //   disabled={!allValuesFilled()}
              //   onClick={() => {
              //     setSaving(false);
              //     handleSave(undefined, false);
              //   }}
              // >
              //   Save Changes
              // </Button>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                disabled={!allValuesFilled() || isPreviewDisabled()}
                onClick={handleShowPreview}
              >
                Preview
              </Button>
            )}
          </Row>
        </CreateEventWrapper>
      )}
    </APIProvider>
  );
};

export default CreateEventPage;

const CreateEventWrapper = styled.div`
  min-height: calc(100vh - 80px);
`;

const InputBodyWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
