import { Stack, Typography } from "@mui/material";
import { Column } from "../styledComponents";
import NothingFound from "../assets/NothingFound.svg";

type Props = {
    header?: string | null;
    body?: string | null;
    img?: "incomplete" | "empty";
};
const NoResults = (props: Props) => {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            <Column crossAxisAlignment="center" spacing={20}>
                <div
                    style={{
                        width: "70%", // Container width
                        height: "auto", // Adjusts height automatically based on aspect ratio
                        maxWidth: "200px", // Maximum width of the container
                    }}
                >
                    <img
                        src={props.img === "incomplete" ? NothingFound : NothingFound} // Replace with your image URL
                        alt={props.img}
                        style={{
                            width: "100%", // Fit image width to container width
                            height: "auto", // Adjusts height automatically based on aspect ratio
                        }}
                    />
                </div>

                <Column crossAxisAlignment="center" spacing={2}>
                    <Typography sx={{ textAlign: "center" }} variant="h3">
                        {props.header}
                    </Typography>
                    <Typography sx={{ textAlign: "center" }} variant="caption">
                        {props.body}
                    </Typography>
                </Column>
            </Column>
        </Stack>
    );
};

export default NoResults;
