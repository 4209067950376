import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { isNumber } from "../services";
import { apiGetRequest } from "../api";
import LeftSvg from "../assets/svg/left.svg";
import RightSvg from "../assets/svg/right.svg";
import { NextButton, PrevButton } from "./navButtons";

interface DotProps {
  isActive: boolean;
}

const Container = styled("div")<{ borderRadius: string }>((props) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  borderRadius: props.borderRadius,
}));

const ImageContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const ForegroundImage = styled("img")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100%",
  maxHeight: "100%",
  zIndex: 10,
});

const BackgroundImage = styled("img")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  minWidth: "100%",
  minHeight: "100%",
  filter: "blur(10px)",
});

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
});

const Dot = styled("div")<DotProps>(({ isActive }) => ({
  width: "12px",
  height: "12px",
  margin: "0 5px",
  borderRadius: "50%",
  backgroundColor: isActive ? "#57B87C" : "#D3D3D3", // Match the green and light gray colors
  cursor: "pointer",
}));

interface VaryingWidthImageProps {
  imageUrl: string;
  blurImageUrl: string;
  imageList?: string[];
  imageFit?: "width" | "height";
  borderRadius?: string;
  type?: string;
  disableSlider?: boolean;
  currentIndex?: number;
  onIndexChange?: (index: number) => void;
}
// Custom deep comparison function
const isEqual = (value: any, other: any): boolean => {
  if (value === other) return true;

  if (
    typeof value !== "object" ||
    value === null ||
    typeof other !== "object" ||
    other === null
  ) {
    return false;
  }

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) return false;

  for (let key of keysValue) {
    if (
      !Object.prototype.hasOwnProperty.call(other, key) ||
      !isEqual(value[key], other[key])
    ) {
      return false;
    }
  }

  return true;
};

const useDeepCompareEffect = (
  callback: React.EffectCallback,
  dependencies: any[]
) => {
  const currentDependenciesRef = useRef<any[]>();

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  useEffect(callback, [currentDependenciesRef.current]);
};

const VaryingWidthImage: React.FC<VaryingWidthImageProps> = ({
  imageUrl,
  blurImageUrl,
  imageList = [],
  imageFit = "width",
  borderRadius,
  disableSlider = true,
  type,
  currentIndex: externalCurrentIndex,
  onIndexChange,
}) => {
  const [internalCurrentIndex, setInternalCurrentIndex] = useState(0);
  const [fgImage, setFgImage] = useState<string>("");
  const [bgImage, setBgImage] = useState<string>("");
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const currentIndex =
    externalCurrentIndex !== undefined
      ? externalCurrentIndex
      : internalCurrentIndex;

  const fetchImage = async (id: any) => {
    const res = await apiGetRequest(`/media/events/${id}`, undefined, false);
    if (res?.code === "00") {
      return res.data;
    }
    return "";
  };

  const fetchImages = async () => {
    if (isNumber(imageUrl)) {
      const fgImg = await fetchImage(imageUrl);
      setFgImage(fgImg);
      setBgImage(fgImg);
    } else {
      setFgImage(imageUrl);
      setBgImage(imageUrl);
    }

    if (isNumber(blurImageUrl)) {
      const bgImg = await fetchImage(blurImageUrl);
      setBgImage(bgImg);
    } else {
      setBgImage(blurImageUrl);
    }

    const loadedImageList = await Promise.all(
      imageList.map(async (img: any) => {
        if (isNumber(img)) {
          return await fetchImage(img);
        }
        return img;
      })
    );

    const uniqueImages = Array.from(new Set(loadedImageList));
    setLoadedImages(uniqueImages);

    if (currentIndex >= uniqueImages.length) {
      handleIndexChange(0);
    }
  };

  useDeepCompareEffect(() => {
    fetchImages();
  }, [imageUrl, blurImageUrl, imageList]);

  const handleIndexChange = (newIndex: number) => {
    let updatedIndex = newIndex;
    if (updatedIndex < 0) {
      updatedIndex = loadedImages.length - 1;
    } else if (updatedIndex >= loadedImages.length) {
      updatedIndex = 0;
    }

    setIsLastSlide(updatedIndex === loadedImages.length - 1);

    if (onIndexChange) {
      onIndexChange(updatedIndex);
    } else {
      setInternalCurrentIndex(updatedIndex);
    }
    setBgImage(loadedImages[updatedIndex]);
  };

  const handlePrev = () => {
    handleIndexChange(currentIndex - 1);
  };

  const handleNext = () => {
    handleIndexChange(currentIndex + 1);
  };

  useEffect(() => {
    if (!disableSlider && loadedImages.length > 1 && !isLastSlide) {
      const intervalId = setInterval(() => {
        handleNextImage();
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [loadedImages, currentIndex, disableSlider, isLastSlide]);

  const handleNextImage = () => {
    handleIndexChange(currentIndex + 1);
  };

  return (
    <Container borderRadius={borderRadius ?? "15px"}>
      <ImageContainer>
        {loadedImages.length > 0 ? (
          <>
            <ForegroundImage
              src={loadedImages[currentIndex]}
              alt=""
              style={{ width: "auto", height: "auto" }}
            />
            <BackgroundImage src={loadedImages[currentIndex]} alt="" />
          </>
        ) : (
          <>
            <ForegroundImage
              src={fgImage}
              alt=""
              style={{ width: "auto", height: "auto" }}
            />
            <BackgroundImage src={fgImage} alt="" />
          </>
        )}

        {/* {loadedImages.length > 1 && !disableSlider && (
          <>
            <PrevButton svgUrl={LeftSvg} onClick={handlePrev}>
              &lt;
            </PrevButton>
            <NextButton svgUrl={RightSvg} onClick={handleNext}>
              &gt;
            </NextButton>
          </>
        )} */}
      </ImageContainer>

      <DotsContainer>
        {loadedImages.map((_, index) => (
          <Dot
            key={index}
            isActive={currentIndex === index}
            onClick={() => handleIndexChange(index)}
          />
        ))}
      </DotsContainer>
    </Container>
  );
};

export default VaryingWidthImage;
