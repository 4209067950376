import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Box,
  styled,
  Grid,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
} from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SummarizeIcon from "@mui/icons-material/Summarize";

const customColor = "#0CAF6D";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: "transparent",
  textTransform: "none",
  fontSize: "0.8125rem",
  fontWeight: 500,
  padding: "4px 5px",
  minWidth: "auto",
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.3rem",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    padding: theme.spacing(0),
    maxWidth: "800px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: customColor,
  color: "#FFFFFF",
  padding: "16px 24px",
  fontSize: "1.5rem",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    padding: "12px 16px",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "24px",
  [theme.breakpoints.down("sm")]: {
    padding: "16px",
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "75%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  transition: "all 0.3s ease-in-out",
  borderBottom: `4px solid ${theme.palette.divider}`,
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    height: "auto",
  },
}));

interface CurrencyTotal {
  [key: string]: number;
}

interface SummaryData {
  totalEvents: number;
  totalPurchases: number;
  totalSales: CurrencyTotal;
}

const SummaryReportButton = () => {
  const [open, setOpen] = useState(false);
  const [summary, setSummary] = useState<SummaryData>({
    totalEvents: 0,
    totalPurchases: 0,
    totalSales: {},
  });
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    const rows = apiRef.current.getRowModels();
    let totalEvents = new Set();
    let totalPurchases = 0;
    let totalSales: CurrencyTotal = {};

    rows.forEach((row) => {
      totalEvents.add(row.event_name);
      totalPurchases += row.total_purchases;

      const currencySymbol = row.total_sales.replace(/[0-9.,]/g, "").trim();
      const saleValue = parseFloat(row.total_sales.replace(/[^0-9.-]+/g, ""));

      if (!isNaN(saleValue)) {
        totalSales[currencySymbol] =
          (totalSales[currencySymbol] || 0) + saleValue;
      }
    });

    setSummary({
      totalEvents: totalEvents.size,
      totalPurchases: totalPurchases,
      totalSales: totalSales,
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const formatCurrency = (value: number, symbol: string) => {
    return `${symbol}${value.toFixed(2)}`;
  };

  const chartData = [
    { name: "Total Events", value: summary.totalEvents },
    { name: "Total Purchases", value: summary.totalPurchases },
    ...Object.entries(summary.totalSales).map(([symbol, value]) => ({
      name: `Total Sales (${symbol})`,
      value: value,
    })),
  ];

  return (
    <>
      <StyledButton
        size="small"
        startIcon={<SummarizeIcon />}
        onClick={handleOpen}
      >
        Sales Summary
      </StyledButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobile}
        fullWidth
      >
        <StyledDialogTitle>Sales Summary Report</StyledDialogTitle>
        <StyledDialogContent>
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12} sm={6} md={4}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom color="textSecondary" fontSize={isMobile ? "0.9rem" : "1rem"}>
                  Total Events
                </Typography>
                <Typography variant="h3" color="primary" fontSize={isMobile ? "1.8rem" : "2.5rem"}>
                  {summary.totalEvents}
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledPaper elevation={3}>
                <Typography variant="h6" gutterBottom color="textSecondary" fontSize={isMobile ? "0.9rem" : "1rem"}>
                  Total Purchases
                </Typography>
                <Typography variant="h3" color="primary" fontSize={isMobile ? "1.8rem" : "2.5rem"}>
                  {summary.totalPurchases}
                </Typography>
              </StyledPaper>
            </Grid>
            {Object.entries(summary.totalSales).map(
              ([symbol, value], index) => (
                <Grid item xs={12} sm={6} md={4} key={symbol}>
                  <StyledPaper elevation={3}>
                    <Typography variant="h6" gutterBottom color="textSecondary" fontSize={isMobile ? "0.9rem" : "1rem"}>
                      Total Sales ({symbol})
                    </Typography>
                    <Typography variant="h3" color="primary" fontSize={isMobile ? "1.8rem" : "2.5rem"}>
                      {formatCurrency(value, symbol)}
                    </Typography>
                  </StyledPaper>
                </Grid>
              )
            )}
          </Grid>
          {/* <Box sx={{ height: isMobile ? 200 : 300, mt: isMobile ? 5 : 10 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <XAxis dataKey="name" tick={{ fontSize: isMobile ? 10 : 12 }} />
                <YAxis tick={{ fontSize: isMobile ? 10 : 12 }} />
                <Tooltip contentStyle={{ fontSize: isMobile ? 12 : 14 }} />
                <Bar dataKey="value" fill={customColor} />
              </BarChart>
            </ResponsiveContainer>
          </Box> */}
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

const CustomToolbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: isMobile ? "stretch" : "center",
        gap: isMobile ? 1 : 0,
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          borderRadius: "100rem",
          flexGrow: 1,
          marginRight: isMobile ? 0 : "auto",
          marginBottom: isMobile ? 1 : 0,
        }}
      />
      <Box sx={{ display: "flex", gap: "10px", justifyContent: isMobile ? "space-between" : "flex-end" }}>
        <SummaryReportButton />
        <GridToolbarExport />
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;