import React from 'react';
import { Box, Link } from '@mui/material';

interface BannerAdProps {
  adData: {
    campaign_image_url: string;
    campaign_link: string;
    campaign_name: string;
  };
}

const BannerAd: React.FC<BannerAdProps> = ({ adData }) => {
  if (!adData) return null;

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        marginTop: '2rem',
        marginBottom: '2rem',
      }}
    >
      <Link href={adData.campaign_link} target="_blank" rel="noopener noreferrer">
        <img
          src={adData.campaign_image_url}
          alt={adData.campaign_name}
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '200px',
            objectFit: 'cover',
          }}
        />
      </Link>
    </Box>
  );
};

export default BannerAd;