import { useEffect } from 'react';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  purl?: string;
};

const MetaTags = (props: Props) => {
  useEffect(() => {
    const setMetaTag = (name: string, content: string | undefined) => {
      if (!content) return;
      let tag = document.querySelector(`meta[name="${name}"]`) || document.querySelector(`meta[property="${name}"]`);
      if (tag) {
        tag.setAttribute('content', content);
      }
    };

    if (props.title) {
      document.title = props.title;
    }
    setMetaTag('description', props.description);
    setMetaTag('og:title', props.title);
    setMetaTag('og:description', props.description);
    setMetaTag('og:image', props.image);
    setMetaTag('twitter:card', 'summary_large_image');
    setMetaTag('twitter:title', props.title);
    setMetaTag('twitter:description', props.description);
    setMetaTag('twitter:image', props.image);
    setMetaTag('og:url', `https://eventsqa.qasa.me/e/${props.purl}`);
  }, [props]);

  return null;
};

export default MetaTags;
