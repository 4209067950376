// Filename - components/Footer.js

import { useNavigate } from "react-router-dom";
import { Container, Row } from "../styledComponents";
import { useMediaQuery, useTheme } from "@mui/material";

const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        marginTop: "70rem",
        width: "auto",
        borderTop: "2.5px dashed rgba(89, 95, 103, 0.3)",
      }}
    >
      <Row
        mainAxisAlignment="center"
        sx={{ color: "grey", ...(isMatch ? { flexDirection: "column" } : {}) }}
      >
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Events
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace("https://www.qasa.me/pages/contact");
          }}
        >
          Help
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace("https://www.qasa.me/pages/privacy-policy");
          }}
        >
          Privacy Policy
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace(
              "https://www.qasa.me/pages/terms-of-service"
            );
          }}
        >
          ToS
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace("https://www.qasa.me/pages/downloads");
          }}
        >
          Downloads
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace("https://www.qasa.me/pages/faq");
          }}
        >
          FAQs
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#000000a6",
            fontFamily: "Inter",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            window.location.replace("https://www.qasa.me");
          }}
        >
          Qasa Home
        </span>
      </Row>
    </Container>
  );
};
export default Footer;
