import React, { useEffect, useRef, useState } from 'react';
import InputField from '../_shared/components/InputField';
import { Box, List, ListItem, Typography, Paper, ClickAwayListener } from '@mui/material';

type PlaceAutocompleteProps = {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  value: string;
  onChange: (value: string) => void;
};

const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = ({
  onPlaceSelect,
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error('Google Maps JavaScript API is not loaded');
      return;
    }

    autocompleteService.current = new google.maps.places.AutocompleteService();
    placesService.current = new google.maps.places.PlacesService(document.createElement('div'));
  }, []);

  const handleInputChange = (newValue: string) => {
    onChange(newValue);
    if (newValue.length > 0) {
      fetchPredictions(newValue);
    } else {
      clearPredictions();
    }
  };

  const fetchPredictions = (input: string) => {
    if (!autocompleteService.current) return;

    const request: google.maps.places.AutocompletionRequest = {
      input: input,
      types: ['geocode']
    };

    autocompleteService.current.getPlacePredictions(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        setPredictions(results);
        setIsOpen(true);
      } else {
        clearPredictions();
      }
    });
  };

  const clearPredictions = () => {
    setPredictions([]);
    setIsOpen(false);
  };

  const handlePredictionClick = (prediction: google.maps.places.AutocompletePrediction) => {
    if (!placesService.current) return;

    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: prediction.place_id,
      fields: ['formatted_address', 'geometry', 'name']
    };

    placesService.current.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        onChange(place.formatted_address || '');
        onPlaceSelect(place);
        clearPredictions();
      }
    });
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const handleInputFocus = () => {
    if (value.length > 0) {
      fetchPredictions(value);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative">
        <InputField
          ref={inputRef}
          label="Event Address"
          value={value}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          required={true}
        />
        {isOpen && predictions.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              zIndex: 1000,
              width: '100%',
              maxHeight: 200,
              overflowY: 'auto',
              mt: 1,
            }}
          >
            <List>
              {predictions.map((prediction) => (
                <ListItem
                  key={prediction.place_id}
                  onClick={() => handlePredictionClick(prediction)}
                  sx={{ 
                    cursor: 'pointer', 
                    '&:hover': { bgcolor: 'action.hover' },
                    padding: '8px 16px',
                  }}
                >
                  <Typography>{prediction.description}</Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default PlaceAutocomplete;