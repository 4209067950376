import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  TextField,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import { Column } from "../_shared/styledComponents";
import { HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import { CountryCodes } from "../_shared/types";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { urls } from "../_shared/constants";
import { StyledTextField } from "../Auth/AuthPage";

const EditableTextField = React.memo(({ label, value, onChange, options }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleEditClick = useCallback(() => {
    setIsEditing((prev) => !prev);
    if (isEditing) {
      onChange({ target: { value: localValue } });
    }
  }, [isEditing, localValue, onChange]);

  const tooltipTitle = isEditing
    ? "Click to save changes"
    : "Click to edit this field";

  return (
    <StyledTextField
      label={label}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      disabled={!isEditing}
      sx={{"& fieldset": { border: "none" },}}
      InputProps={{
        endAdornment: (
          <Tooltip title={tooltipTitle}>
            <IconButton onClick={handleEditClick}>
              <EditIcon style={{ color: isEditing ? 'inherit' : '#0CAF6D' }} />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
});

const CategoryButtonGroup = React.memo(({ category, selectedPreferences, togglePreference }: any) => (
  <>
    <DashedLine style={{ marginTop: "1rem", padding: "10px" }} />
    <Typography variant="body2" gutterBottom style={{fontWeight: "bold"}}>
      {category.category_name}
    </Typography>
    <DashedLine style={{ marginTop: "1rem", padding: "10px" }} />
    <ResponsiveButtonGrid style={{marginBottom: "100px"}}>
      {category.sub_categories.map((item: any) => (
        <Button
          key={item.id}
          variant="contained"
          onClick={() => togglePreference(category.category_name, item.sub_category_name)}
          style={{
            borderRadius: "100px",
            margin: "15px",
            width: "80%",
            backgroundColor: selectedPreferences[category.category_name]?.has(item.sub_category_name) ? "#255E87" : "white",
            color: selectedPreferences[category.category_name]?.has(item.sub_category_name) ? "white" : "black",
            border: "1px solid #255E87",
          }}
          size="large"
        >
          {item.sub_category_name}
        </Button>
      ))}
    </ResponsiveButtonGrid>
  </>
));

const MyAccountPage = () => {
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
  });
  const [country, setCountry] = useState<CountryCodes>({});
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedPreferences, setSelectedPreferences] = useState<{[key: string]: Set<string>}>({});
  const [userDetails, setUserDetails] = useState<any>(null);
  const [preferenceIdMap, setPreferenceIdMap] = useState<{[key: string]: {[key: string]: number}}>({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchUserProfile();
    getCountryCodes();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (Object.keys(country).length > 0 && userProfile.country) {
      const foundCountry = Object.keys(country).find(
        (key) => 
          country[key].code === userProfile.country ||
          country[key].name.toLowerCase() === userProfile.country.toLowerCase()
      );

      if (foundCountry) {
        setSelectedCountry(foundCountry);
        setUserProfile((prev) => ({
          ...prev,
          country: `${country[foundCountry].code} ${country[foundCountry].name}`
        }));
      }
    }
  }, [userProfile.country, country]);

  const initializePreferences = useCallback((categoriesData: any[], userPreferences: any[]) => {
    const initialPreferences: {[key: string]: Set<string>} = {};
    const idMap: {[key: string]: {[key: string]: number}} = {};
    
    categoriesData.forEach((category: any) => {
      initialPreferences[category.category_name] = new Set();
      idMap[category.category_name] = {};
      category.sub_categories.forEach((subCategory: any) => {
        idMap[category.category_name][subCategory.sub_category_name] = subCategory.id;
      });
    });

    userPreferences.forEach((pref: any) => {
      if (initialPreferences[pref.category_name]) {
        initialPreferences[pref.category_name].add(pref.sub_category_name);
      }
    });

    setPreferenceIdMap(idMap);
    setSelectedPreferences(initialPreferences);
  }, []);

  const fetchCategories = useCallback(async () => {
    try {
      const categoriesRes = await apiGetRequest(urls.categories);
      if (categoriesRes?.code === "00") {
        setCategories(categoriesRes.data);
        return categoriesRes.data;
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    return [];
  }, []);

  const fetchUserProfile = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const response = await apiGetRequest(`/users/my-account`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });
        if (response?.code === "00") {
          const { firstname, lastname, email, phone, country, user_preferences } = response.data;
          setUserProfile({
            firstName: firstname,
            lastName: lastname,
            email,
            phoneNumber: phone,
            country,
          });
          
          const categoriesData = await fetchCategories();
          initializePreferences(categoriesData, user_preferences || []);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }
  }, [fetchCategories, initializePreferences]);

  const updateProfile = useCallback(async () => {
    if (!userDetails?.access_token) return;

    const preferenceList = Object.entries(selectedPreferences).flatMap(([category, items]) => 
      Array.from(items).map(item => preferenceIdMap[category]?.[item]).filter(Boolean)
    );

    const updatedProfileData = {
      firstname: userProfile.firstName,
      lastname: userProfile.lastName,
      preference_list: preferenceList,
      user_email: userProfile.email
    };

    try {
      const response = await apiPostRequest(
        `/users/update_profile`,
        updatedProfileData,
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );
      if (response?.code === "00") {
        console.log("Profile updated successfully");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [userProfile, selectedPreferences, userDetails, preferenceIdMap]);

  useEffect(() => {
    if (Object.keys(selectedPreferences).length > 0) {
      updateProfile();
    }
  }, [selectedPreferences, updateProfile]);

  const togglePreference = useCallback((category: string, item: string) => {
    setSelectedPreferences(prev => {
      const newPreferences = { ...prev };
      if (!newPreferences[category]) {
        newPreferences[category] = new Set();
      }
      if (newPreferences[category].has(item)) {
        newPreferences[category].delete(item);
      } else {
        newPreferences[category].add(item);
      }
      return newPreferences;
    });
  }, []);

  const handleCountryChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedKey = event.target.value as string;
    setSelectedCountry(selectedKey);
    setUserProfile((prev) => ({
      ...prev,
      country: `${country[selectedKey].code} ${country[selectedKey].name}`
    }));
  }, [country]);

  const getCountryCodes = useCallback(async () => {
    const adRes = await apiGetRequest(`${urls.country}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      const countries: CountryCodes = {};
      [...adRes.data].forEach((element: any) => {
        countries[`${element.country_code}`] = {
          code: `${element.dialing_prefix}`.substring(1),
          name: `${element.country_name_in_english}`,
        };
      });

      setCountry(countries);
    }
  }, []);

  const memoizedCategories = useMemo(() => categories.map((category) => (
    <CategoryButtonGroup 
      key={category.id} 
      category={category} 
      selectedPreferences={selectedPreferences}
      togglePreference={togglePreference}
    />
  )), [categories, selectedPreferences, togglePreference]);

  return (
    <InputBodyWrapper>
      <Column>
        <HeaderGreenTopBar />
        <HeaderText>My ACCOUNT</HeaderText>
      </Column>
      <ResponsiveGrid>
        <EditableTextField
          label="First Name"
          value={userProfile.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserProfile((prev) => ({ ...prev, firstName: e.target.value }))
          }
        />
        <EditableTextField
          label="Last Name"
          value={userProfile.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserProfile((prev) => ({ ...prev, lastName: e.target.value }))
          }
        />
        <Box
          display="flex"
          alignItems="center"
          sx={{
            border: "2px solid #79C2D8",
            borderRadius: "100px",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <TextField
            sx={{
              "& fieldset": { border: "none" },
              width: isMobile ? "100%" : 250,
            }}
            select
            value={selectedCountry}
            onChange={handleCountryChange}
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
            disabled={true}
            fullWidth={isMobile}
          >
            {Object.keys(country).map((key) => (
              <MenuItem key={key} value={key}>
                <img
                  src={`https://flagcdn.com/${key.toLowerCase() === "uk" ? "gb" : key.toLowerCase()}.svg`}
                  alt={`${country[key].name} flag`}
                  style={{ width: 20, marginRight: 2 }}
                />
                {`${country[key].name}`}
              </MenuItem>
            ))}
          </TextField>
          {!isMobile && <Box width={10} />}
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiInputLabel-shrink": {
                margin: "19px auto",
                position: "absolute",
                left: "0",
                top: "-5px",
                width: "150px",
                background: "none",
              },
            }}
            value={userProfile.phoneNumber}
            disabled={true}
          />
        </Box>
        <EditableTextField
          label="Email"
          value={userProfile.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUserProfile((prev) => ({ ...prev, email: e.target.value }))
          }
        />
      </ResponsiveGrid>
      <Column>
        <HeaderGreenTopBar />
        <HeaderText>PREFERENCES</HeaderText>
      </Column>
      <Typography variant="body2" gutterBottom>
        Select your preferences to receive tailored event recommendations.
      </Typography>
      <div>
        {memoizedCategories}
      </div>
    </InputBodyWrapper>
  );
};

export default React.memo(MyAccountPage);

const InputBodyWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 10px;
`;

const DashedLine = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px dashed #5F6462;
`;

const ResponsiveGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ResponsiveButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;