import React, { useEffect, useState } from "react";

import { Button, Modal, Select, Form, Input } from "antd";
import "../EventDetails/css/eventDetails.css";

import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { Row } from "../_shared/styledComponents";
import CloseIcon from "@mui/icons-material/Close";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

interface FormState {
  selectedPaymentMethod: string | null;
  selectedNetwork: string | null;
  mobileNumber: string;
  promoCode: string;
  cardNumber: string;
  expiryDate: string;
  price: number;
  cvv: string;
}

const paymentMethod = [
  { value: "momo", label: "Momo" },
  { value: "card", label: "Card Payment" },
];

const network = [
  { value: "mtn", label: "MTN" },
  { value: "vodafone", label: "Telecel" },
  { value: "airteltigo", label: "AirtelTigo" },
];

function MEFCheckout() {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<string | null>(null);
  const [paymentOptions, setPaymentOptions] = useState(paymentMethod);
  const [hubtelUrlToRender, setHubtelUrlToRender] = useState<any>();
  const [stripeElementLoaded, setStripeElementLoaded] = useState(false);
  const [stripeOptions, setStripeOptions] = useState<any>({});

  const [openModal, setOpenModal] = useState(false);

  const [formState, setFormState] = useState<FormState>({
    selectedPaymentMethod: null,
    selectedNetwork: null,
    mobileNumber: "",
    promoCode: "",
    cardNumber: "",
    expiryDate: "",
    price: 0,
    cvv: "",
  });

  const [form] = Form.useForm();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROD_KEY ?? "");

  const [showPaymentHubtel, setShowHubtelPayment] = useState(false);
  const [showPaymentStripe, setShowStripePayment] = useState(false);

  useEffect(() => {
    setOpenModal(true);
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const userCountry = response.data.country_name;
      setCountry(userCountry);

      if (userCountry === "Ghana") {
        setFormState((prevState) => ({
          ...prevState,
          selectedPaymentMethod: "momo",
          price: 1.5,
        }));
        setPaymentOptions([
          { value: "momo", label: "Momo" },
          { value: "card", label: "Card Payment" },
        ]);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          selectedPaymentMethod: "card",
          price: 1,
        }));
        setPaymentOptions([{ value: "card", label: "Card Payment" }]);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    if (formState.selectedPaymentMethod === "card") {
      if (country === "Ghana") {
        setFormState((prevState) => ({
          ...prevState,
          price: 1.5,
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          price: 1,
        }));
      }
    } else if (
      formState.selectedPaymentMethod === "momo" &&
      country === "Ghana"
    ) {
      setFormState((prevState) => ({
        ...prevState,
        price: 1.5,
      }));
    }
  }, [formState.selectedPaymentMethod, country]);

  const handleCustomButtonClick = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        if (formState.selectedPaymentMethod === "momo") {
          try {
            const phoneNumber = formState.mobileNumber.startsWith("0")
              ? formState.mobileNumber.slice(1)
              : formState.mobileNumber;

            const response = await axios.post(
              "https://api.qasa.me/v2/auth/login",
              {
                type: 4,
                phone: `233556306788`,
                name: "Test User",
                country: "Ghana",
              }
            );

            const { data } = response;
            const accessToken = data.data.access_token;

            if (response.data.code === "00") {
              try {
                const ticketResponse = await axios.post(
                  "https://api.qasa.me/v2/payment/purchase-ticket",
                  {
                    total_amount: formState.price,
                    quantity: 1,
                    ticket_id: 141,
                    source_account_type: formState.selectedPaymentMethod,
                    wallet_id: 23,
                    provider: formState.selectedNetwork,
                    phone_number: formState.mobileNumber,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
                );

                if (ticketResponse.data.code === "00") {
                  if (
                    ticketResponse.data.msg ===
                    "Purchased Ticket added successfully"
                  ) {
                    showSuccessModal();
                  } else {
                    showErrorModal();
                  }
                } else {
                  showErrorModal();
                }
              } catch (ticketError) {
                console.error("Error during ticket purchase:", ticketError);
                showErrorModal();
              }
            } else {
              showErrorModal();
            }
          } catch (error) {
            console.error("Error during login:", error);
            showErrorModal();
          }
        } else if (formState.selectedPaymentMethod === "card") {
          try {
            const response = await axios.post(
              "https://api.qasa.me/v2/auth/login",
              {
                type: 4,
                phone: country === "Ghana" ? `233556306788` : `15137126050`,
                name: "Test User",
                country: country === "Ghana" ? "Ghana" : "United States",
              }
            );

            const { data } = response;
            const accessToken = data.data.access_token;

            const cardPaymentPayload =
              country === "Ghana"
                ? {
                    total_amount: formState.price,
                    quantity: 1,
                    ticket_id: 141,
                    source_account_type: formState.selectedPaymentMethod,
                    wallet_id: 23,
                    card_details: {
                      cvc: formState.cvv,
                      expiry: formState.expiryDate,
                      number: formState.cardNumber,
                    },
                  }
                : {
                    total_amount: formState.price,
                    quantity: 1,
                    ticket_id: 140,
                    source_account_type: "card",
                    currency: "usd",
                    wallet_id: 32,
                  };

            const cardPaymentResponse = await axios.post(
              "https://api.qasa.me/v2/payment/purchase-ticket",
              cardPaymentPayload,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            if (cardPaymentResponse.data.code === "00") {
              if (
                country === "Ghana" &&
                cardPaymentResponse.data.data.payment_details.redirectUrl
              ) {
                setOpenModal(false);
                setHubtelUrlToRender(
                  cardPaymentResponse.data.data.payment_details.redirectUrl
                );
                setOpenModal(false);
                setShowHubtelPayment(true);
              } else if (
                cardPaymentResponse.data.data.payment_details.clientSecret
              ) {
                setOpenModal(false);
                setStripeOptions({
                  clientSecret:
                    cardPaymentResponse.data.data.payment_details.clientSecret,
                });
                setShowStripePayment(true);
              }
            } else {
              showErrorModal();
            }
          } catch (cardPaymentError) {
            console.error("Error during card payment:", cardPaymentError);
            showErrorModal();
          }
        }

        setLoading(false);
      })
      .catch((info) => {
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const closeModal = () => {
    setOpenModal(false);
    setShowHubtelPayment(false);
    setShowStripePayment(false);
  };

  const handleSelectChange = (name: keyof FormState) => (value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const showSuccessModal = () => {
    Modal.success({
      title: "Payment Initiated Successfully",
      content: "Your payment is being processed successfully.",
    });
  };

  const showErrorModal = () => {
    Modal.error({
      title: "Payment Failed",
      content: "There was an issue processing your payment. Please try again.",
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-4">
      <Modal
        className="grid grid-cols-1 items-center mx-auto p-5 mt-12 max-w-md rounded-lg shadow-sm"
        title="Millennium Excellence Award Checkout"
        open={openModal}
        footer={null}
        onCancel={closeModal}
      >
        <Form form={form} layout="vertical" className="w-full">
          <Form.Item
            name="payment"
            className="text-xs"
            label="Payment Method"
            rules={[
              { required: true, message: "Please select a payment method!" },
            ]}
          >
            <Select
              options={paymentOptions}
              className="h-10"
              onChange={handleSelectChange("selectedPaymentMethod")}
              value={formState.selectedPaymentMethod}
            />
          </Form.Item>

          {formState.selectedPaymentMethod === "momo" ? (
            <div>
              <Form.Item
                name="network"
                className="text-xs"
                label="Network"
                rules={[
                  { required: true, message: "Please select a network!" },
                ]}
              >
                <Select
                  options={network}
                  className="h-10"
                  onChange={handleSelectChange("selectedNetwork")}
                  value={formState.selectedNetwork}
                />
              </Form.Item>

              <Form.Item
                name="mobileNumber"
                className="text-xs"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                ]}
              >
                <Input
                  className="h-10"
                  name="mobileNumber"
                  value={formState.mobileNumber}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          ) : formState.selectedPaymentMethod === "card" &&
            country === "Ghana" ? (
            <div>
              <Form.Item
                name="cardNumber"
                className="text-xs"
                label="Card Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your card number!",
                  },
                ]}
              >
                <Input
                  className="h-10"
                  name="cardNumber"
                  value={formState.cardNumber}
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                name="expiryDate"
                className="text-xs"
                label="Expiry Date"
                rules={[
                  {
                    required: true,
                    message: "Please input your card's expiry date!",
                  },
                ]}
              >
                <Input
                  className="h-10"
                  name="expiryDate"
                  value={formState.expiryDate}
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item
                name="cvv"
                className="text-xs"
                label="CVV"
                rules={[
                  {
                    required: true,
                    message: "Please input your card's CVV!",
                  },
                ]}
              >
                <Input
                  className="h-10"
                  name="cvv"
                  value={formState.cvv}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          ) : null}

          {formState.selectedPaymentMethod && (
            <Button
              type="primary"
              onClick={handleCustomButtonClick}
              loading={loading}
              className="w-full mt-4 h-10"
            >
              Pay{" "}
              {formState.selectedPaymentMethod === "momo" ||
              (formState.selectedPaymentMethod === "card" &&
                country === "Ghana")
                ? `₵${formState.price}`
                : `$${formState.price}`}
            </Button>
          )}
        </Form>
      </Modal>

      <div className="flex items-center justify-center min-h-screen p-20">
        <Modal
          className="grid grid-cols-1 items-center mx-auto p-20 mt-12 max-w-md rounded-lg shadow-sm"
          open={showPaymentHubtel}
          onClose={() => {
            setShowHubtelPayment(false);
          }}
          footer={null}
          onCancel={closeModal}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxSizing: "border-box",
              margin: "0 auto",
            }}
          >
            <Row mainAxisAlignment="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Card Payment`}
              </Typography>
              <Container
                onClick={() => {
                  setShowHubtelPayment(false);
                }}
              ></Container>
            </Row>

            <iframe
              title="urlRenderer"
              src={hubtelUrlToRender}
              width="100%"
              height="500px"
              frameBorder="0"
            ></iframe>
          </Box>
        </Modal>
      </div>

      <div className="flex items-center justify-center min-h-screen p-4">
        <Modal
          className="grid grid-cols-1 items-center mx-auto p-5 mt-12 max-w-md rounded-lg shadow-sm"
          open={showPaymentStripe}
          onClose={() => {
            setShowStripePayment(false);
          }}
          footer={null}
          onCancel={closeModal}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxSizing: "border-box",
              margin: "0 auto",
            }}
          >
            <Row>
              <Typography variant="h6" component="h2">
                {`Card Payment`}
              </Typography>
              <Container
                onClick={() => {
                  setOpenModal(false);
                }}
              ></Container>
            </Row>
            <div className="flex items-center justify-center min-h-screen p-4">
              {!stripeElementLoaded && (
                <Container
                  sx={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "2rem 0",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Container>
              )}
              <Elements stripe={stripePromise} options={stripeOptions}>
                <CheckoutForm
                  clientSecret={stripeOptions.client_secret}
                  onReady={() => setStripeElementLoaded(true)}
                />
              </Elements>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default MEFCheckout;
