import React, { useEffect, useRef, useState } from "react";
import { FullWidthColumn } from "../../_shared/styledComponents";
import { Box, ButtonBase } from "@mui/material";
import VaryingWidthImage from "../../_shared/components/imageViewer";
import { HeaderGreenTopBar, HeaderText } from "../../_shared/styled";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { apiGetRequest } from "../../_shared/api";
import { urls } from "../../_shared/constants";
import html2canvas from "html2canvas";
import download from "../../_shared/assets/svg/downloadSvg.svg";
import print from "../../_shared/assets/svg/printSvg.svg";
import styled from "styled-components";

type EventDetails = {
  event_name: string;
  poster: string;
  image_sliders: { image_link: string }[];
  id: number;
};

function QrCodePage() {
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const eventId = params.get("eventId");
  const eventName = params.get("eventName");

  const eventItemData = async () => {
    if (eventId) {
      const res = await apiGetRequest(
        `${urls.events}${eventId}`,
        undefined,
        false
      );
      if (res?.data != null) {
        setEventDetails(res.data);
      }
    }
  };

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleDownloadQRCode = (): void => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current).then((canvas: HTMLCanvasElement) => {
        const link = document.createElement("a");
        link.download = `${eventDetails?.event_name || "event"}_qr_code.png`;
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  const handlePrintQRCode = (): void => {
    const printContent = document.getElementById("qr-code-print-area");
    if (printContent) {
      const windowPrint = window.open("", "", "width=900,height=650");
      if (windowPrint) {
        windowPrint.document.write(`
          <html>
            <head>
              <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                }
                #qr-code-print-area {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              </style>
            </head>
            <body>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
        windowPrint.document.close();
        windowPrint.focus();
        windowPrint.print();
        windowPrint.close();
      }
    }
  };

  useEffect(() => {
    eventItemData();
  }, [eventId]);

  return (
    <div style={{ textAlign: "center" }}>
      <div className="event-details-container">
        <FullWidthColumn>
          <section className="event-details-section">
            <div>
              <HeaderGreenTopBar />
              <h1 className="event-title">
                QR CODE - {eventDetails?.event_name?.toUpperCase()}
              </h1>
            </div>
            <div
              className="event-image-flyer" 
            >
              <Box
                sx={{
                  height: "30vh",
                  width: "100%",
                  objectFit: "cover",
                }}
              >
                <VaryingWidthImage
                  imageUrl={eventDetails?.poster ?? ""}
                  blurImageUrl={eventDetails?.poster ?? ""}
                />
              </Box>
            </div>
            <div
              className=""
              style={{ marginTop: "10px", marginRight: "5opx" }}
            >
              <EventWrapper
                style={{
                  margin: "2rem 0",
                  marginBottom: "0.5rem",
                  padding: "10px",
                }}
              >
                <HeaderText style={{ fontSize: "15px" }}>QR Codes</HeaderText>
              </EventWrapper>
            </div>
          </section>
        </FullWidthColumn>
        <QrCodeContainer>
          <div className="qr-code-container" ref={qrCodeRef} id="qr-code-print-area">
            <QRCode
              value={`${window.location.origin}/event-details?eventId=${eventId}&eventName=${eventName}`}
              size={200}
            />
          </div>
          <div className="button-container">
            <ButtonBase
              onClick={handleDownloadQRCode}
              className="button"
            >
              Download QR Code
              <img
                src={download}
                alt="download"
                className="icon"
              />
            </ButtonBase>
            <ButtonBase
              onClick={handlePrintQRCode}
              className="button"
            >
              Print QR Code
              <img
                src={print}
                alt="print"
                className="icon"
                style={{ marginLeft: "40px",}}
              />
            </ButtonBase>
          </div>
        </QrCodeContainer>
      </div>
    </div>
  );
}

export default QrCodePage;

const EventWrapper = styled.div`
  padding: 2rem;
  overflow: hidden;
  width: 98%;
  background-color: #edf2f7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const QrCodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  
  .button {
    display: flex;
    align-items: center;
    color: #255e87;
    margin-right: 150px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .icon {
    margin-left: 8px;
    width: 30px;
    height: 30px;
  }

@media (max-width: 815px) {
  flex-direction: column;
  align-items: center;
  
  .button-container {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .button {
    margin-right: 0
    
  }
}
`;
 
