import { useCallback, useEffect, useState } from "react";
import { Container, Row } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { useTheme } from "@mui/material/styles";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum, SortedEvents } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import AppBar from "@mui/material/AppBar";
import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { Helmet } from "react-helmet";
import React from "react";
import QrCodeGenerator from "./QrCodeGenerator";
import CreateEventTicket from "./CreateEventTicket";
import EventsListComponent from "../Home/EventsListComponent";
import { DisplayStatusEnum, EventItem } from "../Home/_helpers/types";
import CustomTabPanel from "../_shared/components/customTabPanel";
import { useNavigate } from "react-router-dom";

const EventAdmin = ({ searchTerm }: { searchTerm: string }) => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 200 },
    { field: "event_year", headerName: "Year", width: 100 },
    {
      field: "ticket_name",
      headerName: "Ticket Name",
      width: 200,
      valueGetter: (params) =>
        params.row.ticket_details.map((ticket: any) => ticket.ticket_name),
      renderCell: (params) => (
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          {params.value.map((ticketName: string) => (
            <div key={ticketName}>{ticketName}</div>
          ))}
        </div>
      ),
    },

    {
      field: "price",
      headerName: "Price",
      width: 120,
      valueGetter: (params) =>
        params.row.ticket_details.map(
          (ticket: any) => `${ticket.currency}${ticket.price}`
        ),
      renderCell: (params) => (
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          {params.value.map((ticketPrice: string, index: number) => (
            <div key={index}>{ticketPrice}</div>
          ))}
        </div>
      ),
    },
    {
      field: "total_sales",
      headerName: "Total Sales",
      width: 120,
      valueGetter: (params) =>
        params.row.ticket_details.map((ticket: any) => ticket.total_sales),
      renderCell: (params) => (
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          {params.value.map((totalSales: any, index: number) => (
            <div key={index}>
              {params.row.ticket_details[0]?.currency}
              {totalSales}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "download",
      headerName: "Download Qr Code",
      width: 200,
      valueGetter: (params) =>
        params.row.ticket_details.map((ticket: any) => ticket.ticket_name),
      renderCell: (params) => (
        <>
          <Stack direction={"column"} id={`divToPrint-${params.row.event_id}`}>
            <Stack style={{ display: "none", width: "100%" }}>
              <div className="header">
                <h1>
                  SCAN TO <br />
                  BUY
                  <br />
                  TICKETS
                </h1>
              </div>
              <Row className="row">
                <h1>{params.row.event_name}</h1>
              </Row>

              <Row className="row">
                <img
                  style={{
                    height: 300,
                    width: 300,
                    objectFit: "cover",
                    marginRight: 5,
                  }}
                  alt="image"
                  src={params.row.poster}
                />
                <QrCodeGenerator
                  url={`${
                    process.env.REACT_APP_NODE_ENV == "development"
                      ? process.env.REACT_APP_DEV_QASA_EVENTS_URL ?? ""
                      : process.env.REACT_APP_PROD_QASA_EVENTS_URL ?? ""
                  }e/${`${params.row.event_name}`
                    .toLocaleLowerCase()
                    .replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "")
                    .replaceAll(" ", "-")}`}
                />
              </Row>
            </Stack>
          </Stack>
          <Button
            variant="outlined"
            onClick={() => handleDownloadQRCode(params.row.event_id)}
          >
            Download QR Code
          </Button>
        </>
      ),
    },
    {
      field: "create_promo_code",
      headerName: "Create Promo Code",
      width: 200,
      valueGetter: (params) =>
        params.row.ticket_details.map((ticket: any) => ticket.ticket_name),
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => {
            setEventDetails(params.row);
            setOpenAddPromoCode(true);
          }}
        >
          Create Promo Code
        </Button>
      ),
    },
    {
      field: "create_ticket",
      headerName: "Create Ticket",
      width: 200,
      valueGetter: (params) =>
        params.row.ticket_details.map((ticket: any) => ticket.ticket_name),
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => {
            setEventDetails(params.row);
            setOpenAddEventTicket(true);
          }}
        >
          Create Ticket
        </Button>
      ),
    },
  ];
  const [rows, setRows] = useState<any>([]);
  const [metricsList, setMetricsList] = useState<any>([]);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [openAddPromoCode, setOpenAddPromoCode] = useState<any>(false);
  const [openAddEventTicket, setOpenAddEventTicket] = useState<any>(false);
  const [eventDetails, setEventDetails] = useState<any>(null);

  const [value, setValue] = useState<number>(0);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const newSortEvents = useCallback((events: EventItem[]) => {
    const presentEvents = events.filter(
      (event) => event.display_status === DisplayStatusEnum.live
    );
    const pubEvents = events.filter(
      (event) => event.display_status === DisplayStatusEnum.published
    );
    const draftEvents = events.filter(
      (event) => event.display_status === DisplayStatusEnum.draft
    );
    const pastEvents = events.filter(
      (event) => event.display_status === DisplayStatusEnum.past
    );
    const cancelledEvents = events.filter(
      (event) => event.display_status === DisplayStatusEnum.cancelled
    );

    return {
      pastEvents,
      presentEvents,
      pubEvents,
      draftEvents,
      cancelledEvents,
    };
  }, []);

  function sortEvents(events: EventItem[]): SortedEvents {
    const currentDate = new Date();

    const pastEvents: EventItem[] = [];
    const presentEvents: EventItem[] = [];
    const pubEvents: EventItem[] = [];
    const draftEvents: EventItem[] = [];
    const cancelledEvents: EventItem[] = [];

    events.forEach((event) => {
      const eventDate = new Date(event.event_date);

      if (event.display_status === DisplayStatusEnum.cancelled) {
        cancelledEvents.push(event);
      } else if (event.display_status !== DisplayStatusEnum.draft) {
        pubEvents.push(event);

        const eventDateCopy = new Date(eventDate);
        eventDateCopy.setDate(eventDateCopy.getDate() - 1);

        if (
          eventDate.toDateString() === currentDate.toDateString() ||
          eventDateCopy.toDateString() === currentDate.toDateString()
        ) {
          presentEvents.push(event);
        } else if (eventDate < currentDate) {
          pastEvents.push(event);
        }
      } else {
        draftEvents.push(event);
      }
    });

    presentEvents.sort((a, b) => {
      const dateA = new Date(a.event_date);
      const dateB = new Date(b.event_date);
      return dateA.getTime() - dateB.getTime();
    });

    return {
      pastEvents,
      presentEvents,
      pubEvents,
      draftEvents,
      cancelledEvents,
    };
  }

  const fetchMyEvents = useCallback(async (accessToken: string) => {
    try {
      const myEventsRes = await apiGetRequest(`${urls.myEvents}`, {
        auth: accessToken,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      });

      if (myEventsRes?.code === "00") {
        setMetricsList(myEventsRes?.data ?? []);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, []);

  const handleSessionExpiration = useCallback(() => {
   const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
        fetchMyEvents(userDetailsObject?.access_token);
      } catch (error) {
        // handleSessionExpiration();
        navigate('/')
      }
    }
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
        fetchMyEvents(userDetailsObject?.access_token);
      } catch (error) {
        navigate('/')
      }
    }else{
      navigate('/')
    }

    const checkLoginStatusListener: any = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          handleSessionExpiration();
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(checkLoginStatusListener);
    };
  }, [fetchMyEvents, handleSessionExpiration]);

  const handleDownloadQRCode = (event_id: string) => {
    const divToPrint = document.getElementById(`divToPrint-${event_id}`);
    if (divToPrint) {
      const innerElements = divToPrint.querySelectorAll("*");
      innerElements.forEach((element: any) => {
        element.style.display = "";
      });

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(
          `<html><head><title>Print</title>
          <style>
              body {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0;
                padding: 0;
              }
              .header { 
                text-align: center;
                margin-bottom: 1rem;
                width: 100vw;
                background: #C6E2ED;
                padding: 50px 0;
               }
               .header h1 {
                border: 3px solid black;
                width: fit-content;
                margin: auto;
                padding: 20px;

               }
               .row{
                display: flex;
                justify-content: center;
                gap:1rem;
               }
               .download-button{
                padding: 10px 20px;
                margin-top: 50px;
                border: 1px solid #bbb;
                border-radius: 5px;
                font-size: 16px;
               }

               @media print {

                .download-button {
                    display: none !important; /* Hide the button when printing */
                }
            }
            </style>
          </head><body style="width: 100vw;">`
        );
        newWindow.document.write(divToPrint.innerHTML);
        // Create a download button
        const downloadButton = document.createElement("button");
        downloadButton.innerText = "Download";
        downloadButton.className = "download-button"; // Add a class for styling

        downloadButton.onclick = () => {
          newWindow?.print();
        };
        newWindow?.document.body.appendChild(downloadButton);
        newWindow.document.write("</body></html>");
        newWindow.document.close();
      } else {
        console.error(
          "Unable to open new window. Please check your browser settings."
        );
      }
      innerElements.forEach((element: any) => {
        element.style.display = "none";
      });
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-CustomTabPanel-${index}`,
    };
  }

  return (
    <Container>
      <Row mainAxisAlignment="space-between">
        {/* <Typography variant="h4" sx={{ marginBottom: 10 }}>
          My Events
        </Typography> */}
        {/* <Button onClick={() => setOpenCreateEvent(true)}>Add Event</Button> */}
      </Row>
      <Helmet>
        <title>Event Admin</title>
      </Helmet>

      <Box>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#EDF2F7", borderRadius: "30px" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            // variant="fullWidth"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="full width tabs example"
            sx={{
              backgroundColor: "#EDF2F7",
              borderColor: "divider",
              color: "black",
              borderRadius: "30px",
              borderBottom: "none",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              display: "flex",
              justifyContent: "space-between",
              "& .MuiTab-root": {
                flex: 1,
                textAlign: "center",
              },
            }}
          >
            <Tab
              label="Live Events"
              {...a11yProps(0)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-selected": {
                  backgroundColor: "#D6E4F0",
                  color: "#5F6462",
                  fontWeight: "bold",
                },
              }}
            />
            <Tab
              label="Published Events"
              {...a11yProps(1)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-selected": {
                  backgroundColor: "#D6E4F0",
                  color: "#5F6462",
                  fontWeight: "bold",
                },
              }}
            />
            <Tab
              label="Draft Events"
              {...a11yProps(2)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-selected": {
                  backgroundColor: "#D6E4F0",
                  color: "#5F6462",
                  fontWeight: "bold",
                },
              }}
            />
            <Tab
              label="Past Events"
              {...a11yProps(3)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-selected": {
                  backgroundColor: "#D6E4F0",
                  color: "#5F6462",
                  fontWeight: "bold",
                },
              }}
            />
            <Tab
              label="Cancelled Events"
              {...a11yProps(4)}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-selected": {
                  backgroundColor: "#D6E4F0",
                  color: "#5F6462",
                  fontWeight: "bold",
                },
              }}
            />
          </Tabs>
        </AppBar>

        {/* Live Event */}
        <CustomTabPanel value={value} index={0}>
          {userDetails ? (
            newSortEvents(metricsList).presentEvents.length > 0 ? (
              <EventsListComponent
                events={newSortEvents(metricsList).presentEvents.filter(
                  (event: any) =>
                    searchTerm.length < 1
                      ? true
                      : `${event.event_name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                )}
                header={"LIVE EVENTS"}
                type="Live"
              />
            ) : (
              "No Available Event"
            )
          ) : (
            "No Available Event"
          )}
        </CustomTabPanel>

        {/* Published Event */}
        <CustomTabPanel value={value} index={1}>
          {userDetails ? (
            (newSortEvents(metricsList).pubEvents ?? []).length > 0 ? (
              <EventsListComponent
                events={(newSortEvents(metricsList).pubEvents ?? []).filter(
                  (event: any) =>
                    searchTerm.length < 1
                      ? true
                      : `${event.event_name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                )}
                header={"PUBLISHED EVENTS"}
                type="Published"
              />
            ) : (
              "No Available Event"
            )
          ) : (
            "No Available Event"
          )}
        </CustomTabPanel>

        {/* Draft Event */}
        <CustomTabPanel value={value} index={2}>
          {userDetails ? (
            (newSortEvents(metricsList).draftEvents ?? []).length > 0 ? (
              <EventsListComponent
                events={(newSortEvents(metricsList).draftEvents ?? []).filter(
                  (event: any) =>
                    searchTerm.length < 1
                      ? true
                      : `${event.event_name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                )}
                header={"DRAFTED EVENTS"}
                type="Draft"
              />
            ) : (
              "No Available Event"
            )
          ) : (
            "No Available Event"
          )}
        </CustomTabPanel>

        {/* Past Event */}
        <CustomTabPanel value={value} index={3}>
          {userDetails ? (
            newSortEvents(metricsList).pastEvents.length > 0 ? (
              <EventsListComponent
                events={newSortEvents(metricsList).pastEvents.filter(
                  (event: any) =>
                    searchTerm.length < 1
                      ? true
                      : `${event.event_name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                )}
                header={"PAST EVENTS"}
                type="Past"
              />
            ) : (
              "No Available Event"
            )
          ) : (
            "No Available Event"
          )}
        </CustomTabPanel>

        {/* Cancelled Event */}
        <CustomTabPanel value={value} index={4}>
          {newSortEvents(metricsList).cancelledEvents.length > 0 ? (
            <EventsListComponent
              events={newSortEvents(metricsList).cancelledEvents.filter(
                (event: any) =>
                  searchTerm.length < 1
                    ? true
                    : `${event.event_name}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
              )}
              header={"CANCELLED EVENTS"}
              type="Cancelled"
            />
          ) : (
            "No Available Event"
          )}
        </CustomTabPanel>
      </Box>

      <CreateEventTicket
        open={openAddEventTicket}
        onClose={() => {
          setEventDetails(null);
          setOpenAddEventTicket(false);
        }}
        selectedEvent={eventDetails}
      />
    </Container>
  );
};

export default EventAdmin;
