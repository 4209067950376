import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  useRadioGroup,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));

interface Ticket {
  id: number;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CreateEventPopupProps {
  open: boolean;
  onClose: () => void;
  selectedEvent: any;
}

interface Group {
  id: number;
  group_name: string;
}

const CreateEventTicket: React.FC<CreateEventPopupProps> = ({
  open,
  onClose,
  selectedEvent,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [timesToUse, setTimesToUse] = useState(1);
  const [discountValue, setDiscountValue] = useState(1);
  const [date, setDate] = useState("");
  const [discountType, setDiscountType] = useState<any>("");
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [tickets, setTickets] = useState<Ticket[]>([{ id: 1, name: '', description: '', price: 1, quantity: 1
   }]);
   
  const handleAddTicket = () => {
    const newId = tickets.length > 0 ? tickets[tickets.length - 1].id + 1 : 1;
    setTickets([...tickets, { id: newId, name: '', description: '', price: 1, quantity:1 }]);
  };

  const handleDeleteTicket = (id: number) => {
    setTickets(tickets.filter(ticket => ticket.id !== id));
  };

  const handleTicketChange = (id: number, field: keyof Ticket, value: string|number) => {
    const updatedTickets = tickets.map(ticket => {
      if (ticket.id === id) {
        return { ...ticket, [field]: value };
      }
      return ticket;
    });
    setTickets(updatedTickets);
  };


  const validateFields = (): boolean => {
    // Check if promoCode is not empty
    if (!promoCode.trim()) {
      toast("Please enter a promo code", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if timesToUse is a positive integer
    if (timesToUse <= 0 || !Number.isInteger(timesToUse)) {
      toast("Please enter a valid number of times to use", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if discountValue is a positive number
    if (discountValue <= 0 || isNaN(discountValue)) {
      toast("Please enter a valid discount value", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if date is not empty
    if (!date.trim()) {
      toast("Please select an expiry date", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if discountType is not empty
    if (!discountType) {
      toast("Please select a discount type", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if selectedEvent exists
    if (!selectedEvent) {
      toast("No event selected", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    // Check if userDetails exists
    if (!userDetails) {
      toast("User details not found", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }
  
    return true;
  };
  
  const handleSave = async () => {
   if(true){
    tickets.forEach(async (tic) => {
        const createTicketRes = await apiPostRequest(
          "/ticket/add",
          {
            ticket_name: tic.name,
            description: tic.description,
            price: tic.price,
            quantity: tic.quantity,
            event_id: selectedEvent.event_id,
          },
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        toast(createTicketRes.msg ?? "Ticket Added", {
          type: ErrorSeverityEnum.success,
        });
      });


    onClose();}
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };
  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Typography variant="h6">
          Create Ticket For ({selectedEvent?.event_name}) Event
        </Typography>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <DialogContent>
      <Typography variant="subtitle1" gutterBottom>Event Tickets</Typography>
      {tickets.map((ticket, index) => (
          <Grid container spacing={2} key={ticket.id}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Ticket Name"
                value={ticket.name}
                onChange={(e) =>
                  handleTicketChange(ticket.id, "name", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Ticket Description"
                value={ticket.description}
                onChange={(e) =>
                  handleTicketChange(ticket.id, "description", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Ticket Price"
                type="number"
                value={ticket.price}
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value);
                  // Check if the new quantity is valid (greater than or equal to 1)
                  if (!isNaN(newQuantity) && newQuantity >= 1) {
                    handleTicketChange(ticket.id, "price", newQuantity);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Ticket Quantity"
                value={ticket.quantity}
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value);
                  // Check if the new quantity is valid (greater than or equal to 1)
                  if (!isNaN(newQuantity) && newQuantity >= 1) {
                    handleTicketChange(ticket.id, "quantity", newQuantity);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteTicket(ticket.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <IconButton aria-label="add" onClick={handleAddTicket}>
                   <AddIcon />
                 </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventTicket;
