import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { HeaderText } from "../_shared/styled";
import { Row } from "../_shared/styledComponents";
import { ToggleSwitch } from "../_shared/components/ToggleSwitch";
import { ChevronIconComponent } from "../_shared/components/ChevronIcon";
import CalendarSvg from "../_shared/assets/svg/CalendarGreen.svg";

interface ViewPromoCodePopupProps {
  open: boolean;
  onClose: () => void;
  promoDetails?: any;
  handleEditStatus: () => Promise<void>;
}

const ViewPromoCodePopup: React.FC<ViewPromoCodePopupProps> = ({
  open,
  onClose,
  promoDetails,
  handleEditStatus
}) => {
  const [subscribedEvent, setSubscribedEvent] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(promoDetails?.status === "Y");

  useEffect(() => {
    setIsActive(promoDetails?.status === "Y");
  }, [promoDetails]);

  const handleToggle = async () => {
    setIsActive(!isActive);
    handleEditStatus();
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    const event = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        initFunct();
      }
    );
    setSubscribedEvent(event);
    return () => {
      if (subscribedEvent) {
        EventRegister.removeEventListener(subscribedEvent);
      }
    };
  }, [open]);
  
  return (
    <StyledDialog open={open} onClose={onClose}>
      <HeaderText color="white">VIEW PROMO CODES</HeaderText>
      <Row spacing={10} crossAxisAlignment="center" style={{ marginTop: "1rem" }}>
        <ToggleSwitch checked={isActive} handleChange={handleToggle} />
        <Row spacing={5}>
          <span style={{ fontWeight: "bold" }}>Status:</span>
          <span>{isActive ? "Active" : "Inactive"}</span>
        </Row>
      </Row>

      <Grid container spacing={6} style={{width:"100%"}}>
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "16px" }}>
          <OutContainer>
            {promoDetails?.code}
          </OutContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "16px" }}>
          <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
            <span>{promoDetails?.discount_value}</span>
            <Row>
              <ChevronIconComponent/>
              <span>{promoDetails?.discount_type === "flat_rate" ? "$" : "%"}</span>
            </Row>
          </OutContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "0px" }}>
          <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
            <span>{promoDetails?.expiry_date?.split(" ").slice(0,4).join(" ")}</span>
            <img
              style={{
                width: "18px",
                height: "18px",
                marginRight: "5px",
                color:"green"
              }}
              src={CalendarSvg}
              alt="Calendar"
            />
          </OutContainer>
        </Grid>
       
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "0px" }}>
          <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
            <span>{promoDetails?.quantity}</span>
          </OutContainer>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default ViewPromoCodePopup;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
    width: 90%;
  }
`;

const OutContainer = styled('div')`
  width: 100%;
  height: 60px;
  background: white;
  color: rgba(175, 177, 181, 1);
  border-radius: 100rem;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-right: 0 10px;
`;