import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./drawer";
import QasaLogo from "../assets/qasa_logo.svg";
import { Row } from "../styledComponents";
import { useNavigate } from "react-router-dom";
import AuthPage from "../../Auth/AuthPage";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../types";
import AvatarDemo from "./avatar";
import CreateEventPopup from "../../Event_Admin/createEventsPopup";
import PencilSvg from "../../_shared/assets/svg/pencil.svg";
import { BodyText2 } from "../styled";

const Header = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [showLogin, setShowLogin] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [openCreateEvent, setOpenCreateEvent] = useState<any>(false);

  const [subscribedLoginEvent, setSubscribedLoginEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const initFunction = async () => {
    setUserDetails(null);
    const storedData = localStorage.getItem("userDetails");
    // console.log(storedData)

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        // localStorage.clear();
      }
    }
  };

  useEffect(() => {
    initFunction();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunction();
        }
      )
    );
    setSubscribedLoginEvent(
      EventRegister.addEventListener(EventsEnum.LoginUser, (data: boolean) => {
        setShowLogin(true);
      })
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
      EventRegister.removeEventListener(subscribedLoginEvent);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setUserDetails(null);
    navigate("/");
    EventRegister.emit(EventsEnum.CheckLoginStatus, true);
  };

  return (
    <React.Fragment>
      <CreateEventPopup
        open={openCreateEvent}
        onClose={() => {
          setOpenCreateEvent(false);
        }}
      />
      <AppBar sx={{ background: "#EDF2F7", boxShadow: "none", padding: "0 0", height:"75px" }}>
        <Toolbar id='appHeader' sx={{height:"75px"}}>
          <Row
            crossAxisAlignment="center"
            onClick={() => {
              navigate("/");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Box
              component="img"
              sx={{
                height: 50,
                width: 150,
                margin: "10px 0",
              }}
              alt="image"
              src={QasaLogo}
            />
          </Row>

          
            <>
              <Row
                spacing={20}
                crossAxisAlignment="center"
                sx={{ marginLeft: "auto", color: "grey" }}
              >
                {/* <span
                  style={{
                    fontSize: "16px",
                    color: "#0CAF6D",
                    fontFamily: "Inter",
                    cursor: "pointer",
                    padding: "8px",
                    background: "#E1E6EA",
                    height: 54,
                    borderBottom: "5px solid #0CAF6D",
                    marginLeft: "10px",
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (userDetails) navigate("/create-event");
                    else EventRegister.emit(EventsEnum.LoginUser, false);
                  }}
                >
                  Create Event
                </span> */}

                <span
                  style={{
                    fontSize: "16px",
                    color: "#000000a6",
                    fontFamily: "Inter",
                    cursor: "pointer",
                    padding: "8px",
                  }}
                  onClick={() => {
                    window.location.replace(
                      "https://www.qasa.me/pages/downloads"
                    );
                  }}
                >
                  Downloads
                </span>
                {/* <span
                  style={{
                    fontSize: "16px",
                    color: "#000000a6",
                    fontFamily: "Inter",
                    cursor: "pointer",
                    padding: "8px",
                  }}
                  onClick={() => {
                    window.location.replace(
                      "https://www.qasa.me/pages/contact"
                    );
                  }}
                >
                  Help
                </span> */}
              </Row>
              {
                <Button
                  sx={{
                    marginLeft: isMatch?"10px":"50px",
                    borderRadius: "1000rem",
                    background: "#0CAF6D",
                    width: "140px",
                  }}
                  variant="contained"
                  onClick={() => {
                    if (userDetails) {
                      handleLogout();
                    } else {
                      navigate(`/login?reroute=${window.location.pathname}`)
                    }
                  }}
                >
                  {userDetails ? "Logout" : "Login"}
                </Button>
              }
              {/* {userDetails && <AvatarDemo userDetails={userDetails} handleLogout={handleLogout}/>} */}
            </>
        </Toolbar>
        {/* {showLogin && (
          <AuthPage
            handleCloseLogin={() => {
              setShowLogin(false);
            }}
          />
        )} */}
      </AppBar>
      {/* { userDetails &&
        <div
          style={{
            position: "fixed",
            top: "6.5rem",
            right: "2rem",
            height: "70px",
            zIndex: "1000",
            borderRadius: "100rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BodyText2 style={{ fontSize: "14px" }}>
            Hello<strong>{` ${userDetails?.name}!`} </strong>
          </BodyText2>
          <div style={{ position: "relative", width: "70px", height: "70px" }}>
            <Avatar sx={{ width: 66, height: 66 }}>
              {`${userDetails?.name}`[0]}
            </Avatar>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "25px",
                height: "25px",
                background: "#38A169",
                borderRadius: "200rem",
                border: "3px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  transform:"scale(0.8)"
                }}
                src={PencilSvg}
              />
            </div>
          </div>
        </div>
      } */}
    </React.Fragment>
  );
};

export default Header;
