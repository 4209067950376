import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../EventDetails/css/eventDetails.css";
import styled from "styled-components";
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from "../_shared/api";
import {
  BodyText,
  HeaderGreenTopBar,
  HeaderOption,
  HeaderText,
} from "../_shared/styled";
import AddSvg from "../_shared/assets/svg/add.svg";
import TrashSvg from "../_shared/assets/svg/trash.svg";
import PencilSvg from "../_shared/assets/svg/pencil.svg";
import SendSvg from "../_shared/assets/svg/send.svg";

import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { loadStripe } from "@stripe/stripe-js";
import { baseReceiptUrl, baseUrl, urls } from "../_shared/constants";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { CountryCodes, EventsEnum } from "../_shared/types";
import { toast } from "react-toastify";
import {
  Container,
  Dialog,
  FormControlLabel,
  MenuItem,
  Radio,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTextField } from "../Auth/AuthPage";
import {
  Box,
  Typography,
  Button,
  Modal,
  SelectChangeEvent,
} from "@mui/material";
import { Column, FullWidthColumn, Row } from "../_shared/styledComponents";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import {
  TicketInfo,
  UserWallet,
  Ticket,
  EventData,
  CustomFields,
  Announcement,
} from "../EventDetails/interfaces";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { DisplayStatusEnum } from "../Home/_helpers/types";

interface DotProps {
  isActive: boolean;
}

function CreateNewEventPreview(props: {
  eventDetails: any;
  closePreview: any;
  images: any;
  handleSave: any;
}) {
  const { eventDetails } = props;
  // console.log('Thes i s the event details::::::',eventDetails)
  //   const [eventDetails, setEventDetails] = useState<EventData>();
  const [eventTickets, setEventTickets] = useState<Array<Ticket>>(
    eventDetails?.tickets ?? []
  );
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [promoCode, setPromoCode] = useState("");
  const [eventPoster, setEventPoster] = useState("");
  const navigate = useNavigate();
  const [groupAdmins, setGroupAdmins] = useState<Array<any>>([]);

  const [end_date, setEndDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_date, setEventDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_time, setEventTime] = useState("16:30:00 GMT");
  const [ticketList, setTicketList] = useState(eventTickets.map(() => 0));
  const [currency, setCurrency] = useState("");
  const [customFields, setCustomFields] = useState<CustomFields[]>([]);

  const [country, setCountry] = useState<CountryCodes>({}); // Flag to indicate paste event
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const initFunction = async () => {
    const pathname = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    const adRes = await apiGetRequest(`${urls.country}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      const countries: CountryCodes = {};
      [...adRes.data].forEach((element: any) => {
        countries[`${element.country_code}`] = {
          code: `${element.dialing_prefix}`.substring(1),
          name: `${element.country_name_in_english}`,
        };
      });

      setCountry(countries);
    }

    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        const res = await apiGetRequest(
          `${urls.groupList}${eventDetails?.group_id}`,
          {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (res?.code == "00") {
          setGroupAdmins(res?.data?.group_members ?? []);
        }

        const announcementResponse = await apiGetRequest(
          `${urls.events}${urls.promoterMessage}`,
          {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (announcementResponse?.code == "00") {
          setAnnouncements(announcementResponse?.data ?? []);
        }
      } catch (error) {}
    }

    if (searchParams.has("promCode")) {
      const promCode = searchParams.get("promCode");
      setPromoCode(promCode ?? "");
    }
  };

  useEffect(() => {
    initFunction();
  }, []);

  const sanitizeTimezone = (timezone: string): string => {
    return timezone.replace(",", "/").replace(" ", "");
  };

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!eventDetails?.event_timezone) {
      return "";
    }

    const sanitizedTimezone = eventDetails.event_timezone;
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      // timeZone: sanitizedTimezone,
      // timeZoneName: "short",
    };

    try {
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDateParts = formatter.formatToParts(date);

      let timeString = "";
      let timeZone = "";

      formattedDateParts.forEach(({ type, value }) => {
        if (type === "hour" || type === "minute" || type === "second") {
          timeString += value.padStart(2, "0") + ":";
        }
        if (type === "timeZoneName") {
          timeZone = value;
        }
      });

      timeString = timeString.slice(0, -1);

      return `${timeString} `;
    } catch (error) {
      console.error("Invalid timezone:", sanitizedTimezone);
      return "Invalid timezone";
    }
  };

  const formattedDate = eventDetails?.event_date
    ? new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(new Date(eventDetails.event_date))
    : "Date not available";

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleDotClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  return (
    <>
      <>
        <Helmet>
          <title>{eventDetails?.event_name}</title>
          <meta property="og:title" content={eventDetails?.event_name} />
          <meta property="og:description" content={eventDetails?.description} />
          <meta property="og:image" content={eventDetails?.poster} />
          <meta
            property="og:url"
            content={`https://eventsqa.qasa.me/e/${eventDetails?.purl}`}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={eventDetails?.event_name} />
          <meta
            name="twitter:description"
            content={eventDetails?.description}
          />
          <meta name="twitter:image" content={eventDetails?.poster} />
        </Helmet>
        <CreateEventWrapper>
          <Row
            mainAxisAlignment="space-between"
            crossAxisAlignment="end"
            sx={{ marginTop: "3rem" }}
          >
            <Column>
              <HeaderGreenTopBar />
              <HeaderText>EVENT PREVIEW</HeaderText>
            </Column>
          </Row>
          <br />
          {
            <section className="event-details-section">
              <div className="event-image-flyer">
                <Box
                  // component="img"
                  sx={{
                    height: "50vh",
                    width: "100%",
                    objectFit: "cover",
                  }}
                >
                  {((props?.images ?? []).length > 0 ||
                    eventDetails?.poster) && (
                    <VaryingWidthImage
                      imageUrl={
                        (props?.images ?? []).length > 0
                          ? props?.images[currentImageIndex]
                          : eventDetails?.poster
                      }
                      blurImageUrl={
                        (props?.images ?? []).length > 0
                          ? props?.images[currentImageIndex]
                          : eventDetails?.poster
                      }
                      imageList={
                        (props?.images ?? []).length > 0
                          ? props?.images
                          : [eventDetails?.poster]
                      }
                      disableSlider={false}
                      onIndexChange={setCurrentImageIndex}
                      currentIndex={currentImageIndex}
                    />
                  )}
                </Box>
                <DotsContainer>
                  {props?.images.map((_: any, index: number) => (
                    <Dot
                      key={index}
                      isActive={index === currentImageIndex}
                      onClick={() => handleDotClick(index)}
                    />
                  ))}
                </DotsContainer>
              </div>
              <div className="flex-container">
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT INFORMATION</h2>
                    <p className="event-content-sub-header">
                      Event Name<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {eventDetails.event_name}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Date<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {formattedDate}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Time<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {`${extractTimeFromDate(eventDetails.event_date)} (${
                          eventDetails.event_timezone
                        })`}{" "}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Address<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.venue}
                      </span>
                    </p>
                    {eventDetails.event_description &&(
                        <p className="event-content-sub-header">
                          Event Description<br></br>
                          <span className="event-content-sub-info">
                            {eventDetails.event_description}
                          </span>
                        </p>
                      )}
                  </div>
                </div>
                <div className="vertical-separator"></div>
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT TICKETS</h2>

                    {eventTickets.map((ticket, index) => (
                      <div className="event-pricing-content">
                        <div className="pricing-info">
                          {ticket.ticket_name}
                          <br></br>
                          <span className="event-content-sub-info">
                            {ticket.price != 0 ? ticket.currency : ""}
                            {ticket.price != 0 ? ticket.price : "Free"}
                            &nbsp;
                            <br />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          }
          {eventDetails?.display_status == DisplayStatusEnum.draft && (
            <Row spacing={40} sx={{ marginBottom: "40rem", width: "100%" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  borderColor: "#0CAF6D",
                  color: "#0CAF6D",
                }}
                onClick={() => {
                  props.closePreview();
                }}
              >
                Back To Edit
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "5rem",
                  height: "48px",
                  borderRadius: "100rem",
                  backgroundColor: "#0CAF6D",
                }}
                onClick={() => {
                  props.handleSave();
                }}
              >
                Publish
              </Button>
            </Row>
          )}
        </CreateEventWrapper>
      </>
    </>
  );
}

export default CreateNewEventPreview;

const CreateEventWrapper = styled.div`
  min-height: calc(100vh - 80px);
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
  }
`;

const RedCheckbox = styled(Radio)(({ theme }) => ({
  color: "#79C2D8",
  "&.Mui-checked": {
    color: "#79C2D8",
  },
}));

const AnnouncementContainer = styled.div`
  border-radius: 100rem;
  width: 100%;
  height: "60px";
  width: "100%";
  maxwidth: "755px";
  background: rgba(250, 251, 252, 1);
  display: flex;
  align-items: center;
  padding: 10px 25px;
  margin-bottom: 15px;
  margin-right: 10px;
  flex-grow: 1;

  @media (min-width: 768px) {
    max-width: 755px;
    justify-content: space-between;
  }
`;

const DateContainer = styled.div`
  border-radius: 100rem;
  height: 60px;
  width: 100%;
  max-width: 165px;
  background: rgba(250, 251, 252, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const AnnouncementText = styled(Typography)`
  flex-grow: 1;
`;

const Dot = styled("div")<DotProps>(({ isActive }) => ({
  width: "12px",
  height: "12px",
  margin: "0 5px",
  borderRadius: "50%",
  backgroundColor: isActive ? "#57B87C" : "#D3D3D3", // Match the green and light gray colors
  cursor: "pointer",
}));

const DotsContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
});
