import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  useRadioGroup,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));

interface Ticket {
  id: number;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CreateEventPopupProps {
  open: boolean;
  onClose: () => void;
}

interface Group {
  id: number;
  group_name: string;
}

const CreateEventPopup: React.FC<CreateEventPopupProps> = ({
  open,
  onClose,
}) => {
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<number | "">("");
  const [visibility, setVisibility] = useState<any>("");
  const [tickets, setTickets] = useState<Ticket[]>([
    { id: 1, name: "", description: "", price: 1, quantity: 1 },
  ]);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [groups, setGroups] = useState<Array<Group>>([]);

  const handleAddTicket = () => {
    const newId = tickets.length > 0 ? tickets[tickets.length - 1].id + 1 : 1;
    setTickets([
      ...tickets,
      { id: newId, name: "", description: "", price: 1, quantity: 1 },
    ]);
  };

  const handleDeleteTicket = (id: number) => {
    setTickets(tickets.filter((ticket) => ticket.id !== id));
  };

  const handleTicketChange = (
    id: number,
    field: keyof Ticket,
    value: string | number
  ) => {
    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === id) {
        return { ...ticket, [field]: value };
      }
      return ticket;
    });
    setTickets(updatedTickets);
  };

  const handleSave = async () => {
    const createGroupRes = await apiPostRequest(
      "/group/add",
      {
        group_name: eventName,
        description: `This is the group for the ${eventName} event`,
        visibility: visibility,
      },
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (createGroupRes?.code === "00") {
      toast(createGroupRes.msg ?? "Group Created", {
        type: ErrorSeverityEnum.success,
      });
      const createEventRes = await apiPostRequest(
        "/events/add",
        {
          event_name: eventName,
          event_date: date,
          venue: location,
          visibility: visibility,
          group_id: createGroupRes.data.id,
        },
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (createEventRes?.code === "00") {
        toast(createGroupRes.msg ?? "Event Created", {
          type: ErrorSeverityEnum.success,
        });
        tickets.forEach(async (tic) => {
          const createTicketRes = await apiPostRequest(
            "/ticket/add",
            {
              ticket_name: tic.name,
              description: tic.description,
              price: tic.price,
              quantity: tic.quantity,
              event_id: createEventRes.data.id,
            },
            {
              auth: userDetails.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            }
          );

          toast(createTicketRes.msg ?? "Ticket Added", {
            type: ErrorSeverityEnum.success,
          });
        });
      }
    }

    onClose();
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        // const myGroups = await apiGetRequest(`${urls.groupList}`, {
        //   auth: userDetailsObject?.access_token,
        //   content_type: ContentTypeEnum.APPLICATION_JSON,
        // });

        // if (myGroups?.code === "00") {
        //   console.log(myGroups);
        //   setGroups(myGroups.data);

        // }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };
  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Typography variant="h6">Create Event</Typography>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          type="date"
          label="Date & Time"
          value={date}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDate(e.target.value)}
        />
        <FormControl sx={{ marginBottom: 5 }} fullWidth>
          <FormHelperText>Event Visibility</FormHelperText>

          <Select
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select a payment" }}
          >
            <MenuItem value={"private"}>Private</MenuItem>
            <MenuItem value={"public"}>Public</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="subtitle1" gutterBottom>
          Event Tickets
        </Typography>
        {tickets.map((ticket, index) => (
          <Grid container spacing={2} key={ticket.id}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Ticket Name"
                value={ticket.name}
                onChange={(e) =>
                  handleTicketChange(ticket.id, "name", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Ticket Description"
                value={ticket.description}
                onChange={(e) =>
                  handleTicketChange(ticket.id, "description", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Ticket Price"
                type="number"
                value={ticket.price}
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value);
                  // Check if the new quantity is valid (greater than or equal to 1)
                  if (!isNaN(newQuantity) && newQuantity >= 1) {
                    handleTicketChange(ticket.id, "price", newQuantity);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Ticket Quantity"
                value={ticket.quantity}
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value);
                  // Check if the new quantity is valid (greater than or equal to 1)
                  if (!isNaN(newQuantity) && newQuantity >= 1) {
                    handleTicketChange(ticket.id, "quantity", newQuantity);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteTicket(ticket.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <IconButton aria-label="add" onClick={handleAddTicket}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventPopup;
