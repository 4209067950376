import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { apiPostRequest } from "../_shared/api";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import { Row } from "../_shared/styledComponents";
import { HeaderText } from "../_shared/styled";
import { StyledTextField } from "../Auth/AuthPage";
import { EventData } from "../EventDetails/interfaces";
import { errorMessage } from "../_shared/constants";
import { ChevronIconComponent } from "../_shared/components/ChevronIcon";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
}));

interface Event {
  id: number;
  event_name: string;
  group_id: number;
}

interface CreateEventPopupProps {
  open: boolean;
  onClose: () => void;
  eventDetails?: EventData;
}

interface DiscountOption {
  value: string;
  label: string;
  symbol: string;
}

const CreatePromoCodePopup: React.FC<CreateEventPopupProps> = ({
  open,
  onClose,
  eventDetails,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [timesToUse, setTimesToUse] = useState(1);
  const [discountValue, setDiscountValue] = useState<string>("1");
  const [date, setDate] = useState("");
  const [discountType, setDiscountType] = useState<"percentage" | "flat_rate">(
    "percentage"
  );
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);

  const validateFields = (): boolean => {
    if (!promoCode.trim()) {
      toast("Please enter a promo code", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (timesToUse <= 0 || !Number.isInteger(timesToUse)) {
      toast("Please enter a valid number of times to use", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    const discountValueNumber = parseFloat(discountValue);
    if (isNaN(discountValueNumber) || discountValueNumber <= 0) {
      toast("Please enter a valid discount value", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (discountType === "percentage" && discountValueNumber > 100) {
      toast("Percentage discount cannot exceed 100%", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!date.trim()) {
      toast("Please select an expiry date", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (discountType !== "percentage" && discountType !== "flat_rate") {
      toast("Please select a valid discount type", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    if (!userDetails) {
      toast("User details not found", {
        type: ErrorSeverityEnum.error,
      });
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (validateFields()) {
      const createEventRes = await apiPostRequest(
        "/events/add/promo_code",
        {
          group_id: eventDetails?.group_id,
          expiry_date: date,
          code: promoCode,
          quantity: timesToUse,
          discount_type: discountType,
          discount_value: discountValue,
          event_id: eventDetails?.id,
          status: "Y", // Default to active status
        },
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (createEventRes?.code === "00") {
        toast(createEventRes.msg ?? "Promo code added", {
          type: ErrorSeverityEnum.success,
        });
        window.location.reload();
      } else {
        toast(
          createEventRes.msg === "This promo code already exists for this event"
            ? createEventRes.msg
            : createEventRes.code === "02"
            ? "Promo code cannot be created for this event"
            : errorMessage,
          {
            type: ErrorSeverityEnum.error,
          }
        );
      }

      onClose();
    }
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setInitialDate();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [open]);

  const setInitialDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    setDate(formattedDate);
  };

  const [isDiscountTypeOpen, setIsDiscountTypeOpen] = useState(false);
  const discountOptions: DiscountOption[] = [
    { value: "percentage", label: "% - Percentage", symbol: "%" },
    { value: "flat_rate", label: "$ - Amount", symbol: "$" },
  ];

  const handleDiscountValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value;

    if (newValue === "" || newValue === "0") {
      setDiscountValue("");
    } else {
      const numValue = parseFloat(newValue);
      if (!isNaN(numValue)) {
        if (discountType === "percentage") {
          setDiscountValue(Math.min(Math.max(numValue, 0), 100).toString());
        } else {
          setDiscountValue(Math.max(numValue, 0).toString());
        }
      }
    }
  };

  const handleDiscountTypeChange = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newType = e.target.value as "percentage" | "flat_rate";
    setDiscountType(newType);
    setDiscountValue("1"); // Reset to default value when changing type
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <HeaderText color="white">PROMO CODES</HeaderText>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "12px" }}>
          <StyledTextField
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              // maxWidth: "600px",
              margin: 0,
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                  background: "white",
                },
              },
            }}
            onChange={(e) => {
              const value = e.target.value.slice(0, 14);
              setPromoCode(value);
            }}
            margin="normal"
            id="code_name"
            label="Code Name"
            name="code_name"
            value={promoCode}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "12px" }}>
          <Row
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "100rem",
              background: "white",
              border: "2px solid #79C2D8",
            }}
            isResponsiveFlexDirection="row"
          >
            <StyledTextField
              type="number"
              variant="outlined"
              sx={{
                border: "none",
                "& fieldset": {
                  border: "none",
                  borderRadius: "100rem",
                  margin: 0,
                },
                width: "50%",
                margin: 0,
              }}
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: "left",
                    borderRadius: "100rem",
                    height: "20px",
                  },
                  min: 0,
                  max: discountType === "percentage" ? 100 : undefined,
                  step: "any",
                },
              }}
              onChange={handleDiscountValueChange}
              margin="normal"
              id="discount_value"
              label="Discount"
              name="discount_value"
              value={discountValue}
            />
            <StyledTextField
              select
              variant="outlined"
              sx={{
                border: "none",
                "& fieldset": {
                  border: "1px solid #79C2D8",
                  borderRadius: "100rem",
                  margin: 0,
                },
                width: "100%",
                margin: 0,
                borderRadius: "100rem",
                background: "white",
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                },
              }}
              SelectProps={{
                IconComponent: ChevronIconComponent,
                renderValue: (value) => {
                  const symbol = value === "flat_rate" ? "$" : "%";
                  return (
                    <div style={{ display: "flex", alignItems: "start" }}>
                      <span>{symbol}</span>
                      <span style={{ marginLeft: "5px" }}>
                        {value === "flat_rate" ? "Amount" : "Percentage"}
                      </span>
                    </div>
                  );
                },
              }}
              onChange={handleDiscountTypeChange}
              margin="normal"
              id="discount"
              name="discount"
              value={discountType}
            >
              <MenuItem value="flat_rate">$ - Amount</MenuItem>
              <MenuItem value="percentage">% - Percentage</MenuItem>
            </StyledTextField>
          </Row>
        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "12px" }}>
          <StyledTextField
            type="number"
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              // maxWidth: "600px",
              margin: 0,
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                  background: "white",
                },
              },
            }}
            onChange={(e) => setTimesToUse(parseInt(e.target.value))}
            margin="normal"
            id="quantity"
            label="Quantity"
            name="quantity"
            value={timesToUse}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} style={{ marginTop: "16px" }}>
          <StyledTextField
                  type="number"
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              // maxWidth: "600px",
              margin: 0,
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                },
                min: discountType === 'percentage' ? 1 : 1,
                max: discountType === 'percentage' ? 100 : Infinity,
              },
            }}
            onChange={(e) =>{ 
              const newQuantity = parseInt(e.target.value);
              if (!isNaN(newQuantity) && newQuantity > 0) {
              setDiscountValue(discountType === 'percentage'?newQuantity>100?100:newQuantity:newQuantity)

              }


            }}
            margin="normal"
            id="discount_value"
            label="Discount Value"
            name="discount_value"
            value={discountValue}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "12px" }}>
          <StyledTextField
            variant="outlined"
            type="date"
            sx={{
              "& fieldset": {
                border: "1px solid #79C2D8",
                borderRadius: "1000px",
                margin: 0,
              },
              width: "100%",
              height: "56px",
              margin: 0,
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  height: "20px",
                  background: "white",
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                  paddingLeft: "14px",
                },
              },
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                // paddingTop: "8px", // Adjust this as needed
                zIndex: 1,
              },
            }}
            onChange={(e) => setDate(e.target.value)}
            margin="normal"
            label="Expiration Date"
            name="expiration_date"
            value={date}
          />
        </Grid>
      </Grid>

      <Row
        crossAxisAlignment="center"
        spacing={20}
        style={{ marginTop: "16px" }}
        isResponsiveFlexDirection="row"
      >
        <Button
          variant="contained"
          sx={{
            width: "170px",
            height: "60px",
            borderRadius: "100rem",
            backgroundColor: "white",
            border: "2px solid #0CAF6D",
          }}
          onClick={onClose}
        >
          <Typography
            style={{
              color: "#0CAF6D",
              fontWeight: "700",
            }}
          >
            Discard
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "170px",
            height: "60px",
            borderRadius: "100rem",
            backgroundColor: "#0CAF6D",
            border: "2px solid #0CAF6D",
          }}
          onClick={handleSave}
        >
          <Typography
            style={{
              color: "#fff",
              fontWeight: "700",
            }}
          >
            Add
          </Typography>
        </Button>
      </Row>

      {/* </DialogContent> */}
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions> */}
    </StyledDialog>
  );
};

export default CreatePromoCodePopup;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
    width: 100%;
  }
`;
