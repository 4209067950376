import React from "react";
import { TextField, styled } from "@mui/material";

type Props = {
  onChange: (value: string) => void;
  label?: string;
  type?: string;
  hint?: string;
  multiline?: boolean;
  value?: number | string;
};

const CustomTextField: React.FC<Props> = ({
  onChange,
  label,
  hint,
  multiline = false,
  type,
  value,
}) => {
  return (
    <CustomField
      variant="outlined"
      multiline={multiline}
      minRows={multiline ? 5 : 1}
      onChange={(e) => onChange(e.target.value)}
      margin="normal"
      label={label}
      placeholder={hint}
      type={type}
      fullWidth
      value={value}
    />
  );
};

export default CustomTextField;

const CustomField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    textAlign: "left",
  },

  "& .MuiInputLabel-shrink": {
    margin: "0 auto",
    position: "absolute",
    left: "0",
    top: "-3px",
    width: "150px",
    background: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "25px",
    backgroundColor: "white",

    "& fieldset": {
      borderColor: "#79C2D8",
    },
    "&:hover fieldset": {
      borderColor: "#79C2D8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#79C2D8",
    },
  },
}));
