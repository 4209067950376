import styled from "styled-components";
import { ChangeEvent, useState } from "react";

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  /* transform: scale(0.5); */
`;

const Switch = styled.div`
  position: relative;
  width: 50px;
  height: 18px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: rgba(12, 175, 109, 1)    ;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

export const ToggleSwitchWhiteKnob = (props:{checked: any, handleChange: any}) => {
  const {checked, handleChange} = props;


  return (
    <Label>
      <Input checked={checked} type="checkbox" onChange={handleChange} />
      <Switch />
    </Label>
  );
};
