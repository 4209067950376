import { useState } from "react";
import QRCode from "react-qr-code";
import "./css/qr.css"

function QrCodeGenerator({url, color, size, height, width}:{url: string, size?: number, color?:string, width?:number, height?:number}) {

    return (
      <div className="qrcode__container" style={{width: width, height:height}}>
        <div className="qrcode__container--parent">
         
          {url && (
            <div className="qrcode__download">
              <div className="qrcode__image">
                <QRCode value={url} size={size ?? 300} fgColor={color??"#FFB854"}/>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  export default QrCodeGenerator;