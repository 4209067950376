import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import DataGridTable from "../_shared/components/datagridTable";
import { Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import TicketSummaryCustomToolbar from "./ticketSalesSummary";

const MyTicketSales: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 200 },
    { field: "ticket_name", headerName: "Ticket Name", width: 200 },
    { field: "ticket_id", headerName: "Ticket ID", width: 100 },
    { field: "user_name", headerName: "User Name", width: 200 },
    { field: "purchase_date", headerName: "Purchase Date", width: 200 },
    { field: "price", headerName: "Price", width: 120 },
    { field: "fee", headerName: "Fees", width: 120 },
    { field: "tax", headerName: "Tax", width: 120 },
    { field: "total", headerName: "Total", width: 120 },
    { field: "promo_code", headerName: "Promo Code", width: 120 },
    { field: "transaction_id", headerName: "Transaction ID", width: 200 },
  ];

  const [rows, setRows] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>(null);

  const location = useLocation();
  const { eventData } = location.state || { eventData: {} };

  const fetchData = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const myEventsRes = await apiGetRequest(`${urls.myTickets}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        console.log("myEventsRes:", myEventsRes);

        if (myEventsRes?.code === "00") {
          const filteredEvents = myEventsRes.data.filter(
            (event: { event_name: string }) =>
              event.event_name === eventData.event_name
          );

          let rowId = 0;
          const formattedRows = filteredEvents.flatMap((event: any) =>
            event.ticket_details.flatMap((ticket: any) =>
              ticket.user_metrics.map((user: any) => {
                // Create a row for each purchase metric under this user
                return user.purchase_metrics.map((purchaseMetric: any) => {
                  const price = parseFloat(purchaseMetric.price);
                  const fee = parseFloat(purchaseMetric.fee);
                  const tax = parseFloat(purchaseMetric.tax);
                  const total = price + fee + tax;

                  return {
                    id: rowId++, // Unique ID for each row
                    event_name: event.event_name,
                    ticket_name: ticket.ticket_name,
                    ticket_id: ticket.ticket_id,
                    user_name: user.name, // This will now correctly associate each purchase with its user
                    purchase_date: purchaseMetric.date_created,
                    price: `${purchaseMetric.currency}${price.toFixed(2)}`,
                    fee: `${purchaseMetric.currency}${fee.toFixed(2)}`,
                    tax: `${purchaseMetric.currency}${tax.toFixed(2)}`,
                    total: `${purchaseMetric.currency}${total.toFixed(2)}`,
                    promo_code: purchaseMetric.promo_code || "N/A",
                    transaction_id: purchaseMetric.transaction_id,
                  };
                });
              }).flat() // Flatten the user metrics array
            )
          );

          setRows(formattedRows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.clear();
        setUserDetails(null);
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    } else {
      setUserDetails(null);
    }
  }, [eventData.event_name]);

  useEffect(() => {
    fetchData();

    const subscribedEvent: any = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          fetchData();
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [fetchData]);

  return (
    <Container>
      <Typography variant="h4" sx={{ marginBottom: 10 }}>
        Event Sales Details
      </Typography>
      <Helmet>
        <title>Event Sales Details</title>
      </Helmet>

      {userDetails ? (
        <DataGridTable
          columns={columns}
          rows={rows}
          page="ticket_sales"
          slots={{
            toolbar: TicketSummaryCustomToolbar,
          }}
        />
      ) : (
        <div style={{ marginTop: "20vh" }}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"} 
          />
        </div>
      )}
    </Container>
  );
};

export default MyTicketSales;