import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Column, Container } from "../_shared/styledComponents";
import QasaLogo from "../_shared/assets/qasa_logo_no_bg.png";
import { useEffect, useState } from "react";
import { StyledTextField } from "./AuthPage";
import { apiGetRequest, apiPostRequest } from "../_shared/api";
import { toast } from "react-toastify";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { Helmet } from "react-helmet";
import { stripPhoneNumber } from "../_shared/functions";
import { urls, validFieldsErrorMessage } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { CountryCodes } from "../_shared/types";

type Props = {
  setOtpCode: (x: string) => void;
  setViewOtp: (x: boolean) => void;
  handleSendOtp: (
    mobile: string,
    country: string,
    firstName: string,
    lastName: string
  ) => void;
  isCheckoutPage?: boolean;
  disableButton?: boolean;
};

const Login = (props: Props) => {
  const [firstName, setFirstName] = useState<string>(""); // State to hold the selected country code
  const [lastName, setLastName] = useState<string>(""); // State to hold the selected country code
  const [buttonText, setText] = useState<string>("LOGIN");
  const [isPasting, setIsPasting] = useState<boolean>(false); // Flag to indicate paste event
  const [country, setCountry] = useState<CountryCodes>({}); // Flag to indicate paste event
  const [countryCode, setCountryCode] = useState<any>("1 United States"); // State to hold the selected country code, defaulted to US
  const [phoneNumber, setPhoneNumber] = useState<string>(""); // State to hold the selected country code

  // Function to handle pasted input
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    setIsPasting(true); // Set the flag to true for paste event
    const pastedText: string = e.clipboardData.getData("text");
    const strippedNumber: string = stripPhoneNumber(pastedText, true);
    setPhoneNumber(strippedNumber);
  };

  // Function to handle typed input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!isPasting) {
      const typedNumber: string = e.target.value;
      const strippedNumber: string = stripPhoneNumber(typedNumber, false);
      setPhoneNumber(strippedNumber);
    }
    setIsPasting(false); // Reset the flag after handling the input
  };

  const handleCountryCodeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCountryCode(event.target.value);
  };

  const handleSendOtp = async () => {
    const parts = countryCode.split(" ", 2);
    const code = parts[0];
    const selectedCountry = countryCode.substring(parts[0].length + 1);
    const internationalNumber = `${code}${
      phoneNumber[0] === "0" ? phoneNumber.substring(1) : phoneNumber
    }`;

    if (
      firstName.length < 3 ||
      lastName.length < 3 ||
      internationalNumber.length < 3
    ) {
      toast(
        validFieldsErrorMessage, {
        type: ErrorSeverityEnum.error,
      });
      return;
    }

    await props.handleSendOtp(
      internationalNumber,
      selectedCountry,
      firstName,
      lastName
    );

    props.setViewOtp(true);
  };

  const setDefaultCountries = () => {
    const defaultCountries: CountryCodes = {
      'US': { code: '1', name: 'United States' },
      'UK': { code: '44', name: 'United Kingdom' },
      'CA': { code: '1', name: 'Canada' },
      'GH': { code: '233', name: 'Ghana' },
      'ZA': { code: '27', name: 'South Africa' }
    };
    setCountry(defaultCountries);
  };

  useEffect(() => {
    setDefaultCountries();
  }, []);

  const getFlagUrl = (countryCode: string) => {
    console.log(countryCode, "countryCode");
    if (countryCode.toLowerCase() === "uk") {
      return "https://flagcdn.com/gb.svg";
    }
    return `https://flagcdn.com/${countryCode.toLowerCase()}.svg`;
  };

  return (
    <Column crossAxisAlignment="center" sx={props.isCheckoutPage?{flexDirection:"row", gap:"1rem", flexWrap:"wrap"}:{}}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {!props.isCheckoutPage && (
        <Box
          component="img"
          sx={{
            width: 100,
            margin: "10px 0",
          }}
          alt="image"
          src={QasaLogo}
        />
      )}

      {!props.isCheckoutPage && (
        <Typography
          color={"#4f4f4f"}
          sx={{ width: "250px", textAlign: "center", fontSize: "12px" }}
        >
          Unlock seamless, real-time communication with QASA. Send texts, share
          media, and even make voice/video calls online.
        </Typography>
      )}

      <StyledTextField
        sx={{
          // "& fieldset": { border: props.isCheckoutPage?" 1px solid #79C2D8":"none" , borderRadius:"100rem"},
          width: "100%",
          maxWidth: "500px",
        }}
        onChange={(e) => {
          // console.log(e.target.value);
          setFirstName(e.target.value);
        }}
        margin="normal"
        id="firstName"
        label="First Name"
        name="firstName"
      />
      <StyledTextField
        sx={{
          // "& fieldset": { border: props.isCheckoutPage?" 1px solid #79C2D8":"none", borderRadius:"100rem" },
          width: "100%",
          maxWidth: "500px",
        }}
       
        onChange={(e) => {
          // console.log(e.target.value);
          setLastName(e.target.value);
        }}
        margin="normal"
        id="lastName"
        label="Last Name"
        name="lastName"
      />

      <Container
        sx={{
          width: "100%",
          maxWidth: "500px",
          background: props.isCheckoutPage ? "white" : "rgba(240, 240, 240, 1)",
          borderRadius: "100rem",
          height: "55px",
          marginTop: "10px",
        }}
      >
        <Box display="flex" alignItems="center" sx={{border: props.isCheckoutPage?"1px solid #79C2D8":"none", borderRadius:"100rem" }}>
          {/* Country code dropdown */}
          <TextField
            sx={{
              "& fieldset": { border: "none" },
            }}
            select
            value={countryCode}
            onChange={handleCountryCodeChange}
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {/* Country code options with flags in specified order */}
            {Object.keys(country).map((key) => (
              <MenuItem
                key={key}
                value={`${country[key].code} ${country[key].name}`}
              >
                <img
                  src={getFlagUrl(key)}
                  alt={`${country[key].name} flag`}
                  style={{ width: 25, marginRight: 8}}
                />
                {`${country[key].code} (${country[key].name})`}
              </MenuItem>
            ))}
          </TextField>

          {/* Spacer between dropdown and TextField */}
          <Box width={10} />

          {/* Actual text field for phone number */}
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            onPaste={handlePaste}
            onChange={handleChange}
            InputProps={
              {inputMode:"numeric",}
            }
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiInputLabel-shrink": {
                margin: "19px auto",
                position: "absolute",
                left: "0",
                top: "-5px",
                width: "150px", // Need to give it a width so the positioning will work
                background: "none", // Add a white background as below we remove the legend that had the background so text is not meshing with the border
              },
            }}
            value={phoneNumber}
          />
        </Box>
      </Container>

      {
        <Button
        variant="contained"
        sx={{
          width: "100%",
          maxWidth: "500px",
          height: "48px",
          borderRadius: "100rem",
          backgroundColor: "#0CAF6D",
          marginTop: "20px",
        }}
        disabled={
          (props.isCheckoutPage && props.disableButton) ||
          !phoneNumber ||
          !firstName ||
          !lastName
        }
        onClick={handleSendOtp}
      >
        {props.isCheckoutPage?"Initiate Purchase":buttonText}
      </Button>}

      {!props.isCheckoutPage && (
        <Button
          sx={{ marginTop: "20px" }}
          onClick={() => {
            if (buttonText == "LOGIN") {
              // console.log("SIGNUP");
              setText("SIGNUP");
            } else {
              setText("LOGIN");
              // console.log("LOGIN");
            }
          }}
          variant="text"
        >
          {buttonText == "LOGIN"
            ? "Click here to Sign Up"
            : "Already have an account? Login instead"}
        </Button>
      )}
    </Column>
  );
};

export default Login;