import ChevronIcon from '../assets/svg/chevron.svg';
export const ChevronIconComponent = () => (
    <img
      style={{
        // width: "18px",
        // height: "18px",
        marginRight: "10px",
      }}
      src={ChevronIcon}
      alt="Chevron Icon"
    />
  );
  