import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  Box,
  Typography,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import { apiPostRequest } from "../../_shared/api";
import { ContentTypeEnum } from "../../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../../_shared/enum/error-severity.enum";
import { StyledTextField } from "../../Auth/AuthPage";
import AddSvg from "../../_shared/assets/svg/add.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(37, 94, 135, 1)",
    },
    secondary: {
      main: "#79C2D8",
    },
  },
});

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(37, 94, 135, 1)",
  color: "white",
  borderRadius: "10px",
  padding: theme.spacing(3),
  outline: "none",
  width: "100%",
  maxWidth: "400px",
  maxHeight: "90vh",
  overflow: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    maxHeight: "80vh",
  },
}));

const ModalTitle = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: "20px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#0CAF6D",
  color: "white",
  borderRadius: "100px",
  padding: "10px 20px",
  minWidth: "120px",
  maxWidth: "200px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    backgroundColor: "#0CAF6D",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    padding: "8px 16px",
  },
}));

export const StyledButton1 = styled(Button)(({ theme }) => ({
  backgroundColor: "#0CAF6D",
  color: "white",
  borderRadius: "100px",
  padding: "10px 20px",
  minWidth: "120px",
  // maxWidth: "200px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    backgroundColor: "#0CAF6D",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    padding: "8px 16px",
  },
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",
  gap: "10px",
});

const CashPaymentCompletion: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [userDetails, setUserDetails] = useState<any>(null);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  const getUserData = () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        console.error("Error parsing user details:", error);
        localStorage.clear();
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTicketId("");
  };

  const handleSubmit = async () => {
    if (!ticketId) {
      toast("Please enter a Ticket ID.", { type: "error" });
      return;
    }

    if (!userDetails || !userDetails.access_token) {
      toast("User authentication required.", { type: "error" });
      return;
    }

    try {
      const response = await apiPostRequest(
        `/payment/complete-cash-payment/${ticketId}`,
        {},
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (response.code === "00") {
        toast(response.msg || "Cash payment completed successfully", {
          type: ErrorSeverityEnum.success,
        });
        handleClose();
      } else {
        throw new Error(response.msg || "Failed to complete cash payment");
      }
    } catch (error) {
      toast((error as Error).message || "An error occurred", {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledButton1 variant="contained" onClick={handleOpen} sx={{ mt: 2, gap: 1 }}>
        <span>Complete Cash Payment</span>
        <img
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          src={AddSvg}
        />
      </StyledButton1>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="cash-payment-modal-title"
        disableScrollLock={false}
      >
        <ModalContent>
          <ModalTitle id="cash-payment-modal-title">
            Complete Cash Payment
          </ModalTitle>
          <StyledTextField
            autoFocus
            margin="dense"
            id="ticketId"
            label="Ticket ID"
            type="text"
            fullWidth
            variant="outlined"
            value={ticketId}
            onChange={(e) => setTicketId(e.target.value)}
          />
          <ButtonContainer>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButton onClick={handleSubmit}>Complete Payment</StyledButton>
          </ButtonContainer>
        </ModalContent>
      </StyledModal>
    </ThemeProvider>
  );
};

export default CashPaymentCompletion;
