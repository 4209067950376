import React, { useEffect, useState, useCallback } from "react";
import DataGridTable from "../_shared/components/datagridTable";
import { Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import CustomToolbar from "./summary";

const MyEventSales: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 400 },
    {
      field: "total_purchases",
      headerName: "Total Number of Purchases (Quantity)",
      width: 400,
      align: "center",
      headerAlign: "center"
    },
    { field: "total_sales", headerName: "Total Amount of Sales", width: 300, align: "center", headerAlign: "center" },
  ];

  const [rows, setRows] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>(null);

  const fetchData = useCallback(async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);

        const myEventsRes = await apiGetRequest(`${urls.analytics}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (myEventsRes?.code === "00") {
          const eventMap = new Map();

          myEventsRes.data.forEach((event: any) => {
            const totalPurchases = event.ticket_count.reduce((sum: number, ticket: any) => sum + ticket.quantity_sold, 0);
            const totalSales = parseFloat(event.gross_sales_amount);

            if (eventMap.has(event.event_name)) {
              const existingEvent = eventMap.get(event.event_name);
              existingEvent.total_purchases += totalPurchases;
              existingEvent.total_sales += totalSales;
            } else {
              eventMap.set(event.event_name, {
                id: event.event_id,
                event_name: event.event_name,
                total_purchases: totalPurchases,
                total_sales: totalSales,
                currency: event.currency
              });
            }
          });

          const aggregatedData = Array.from(eventMap.values()).map((event: any) => ({
            ...event,
            total_sales: `${event.currency}${event.total_sales.toFixed(2)}`
          }));

          setRows(aggregatedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.clear();
        setUserDetails(null);
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    } else {
      setUserDetails(null);
    }
  }, []);

  useEffect(() => {
    fetchData();

    const subscribedEvent: any = EventRegister.addEventListener(
      EventsEnum.CheckLoginStatus,
      (data: boolean) => {
        if (data) {
          fetchData();
        }
      }
    );

    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [fetchData]);

  return (
    <Container>
      <Typography variant="h4" sx={{marginBottom: 10}}>Event Sales</Typography>
      <Helmet><title>Event Sales</title></Helmet>

      {userDetails ? (
        <DataGridTable 
        columns={columns} 
        rows={rows} 
        page="event_sales"
        slots={{
          toolbar: CustomToolbar
        }}
      />
      ) : (
        <div style={{marginTop:"20vh"}}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyEventSales;