import styled from "styled-components";

export const HeaderGreenTopBar = styled.div`
  background-color: #0caf6d;
  width: 50px;
  height: 5px;
  border-radius: 200px;
  margin-bottom: 10px;
`;

export const HeaderText = styled.span`
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

export const HeaderOption = styled.span`
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
`;

export const BodyText = styled.span`
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;

`;
export const BodyText2 = styled.span`
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;


`;