import React, { useEffect, useState } from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Column, Container } from '../_shared/styledComponents'
import { Button, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { ErrorSeverityEnum } from '../_shared/enum/error-severity.enum'
import { Helmet } from 'react-helmet'
import { otpErrorMessage } from '../_shared/constants'
type Props = {
  otpCode: string;
  loginFunct: ()=>void;
  handleSendOtp: (

  ) => void;
}
export const OtpPage = (props: Props) => {
  const [otp, setOtp] = React.useState('')
  const initialSeconds: number = 60;
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [intervalId, setIntervalId] = useState<any | null>(null);

  const startTimer = () => {
    const newIntervalId: any = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds>0?prevSeconds - 1: prevSeconds);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const resetTimer = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
    setSeconds(initialSeconds);
  };

  useEffect(() => {
    startTimer();

    // Clear the interval when the component is unmounted
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, []);

  // Reset the timer when it reaches 0
  useEffect(() => {
    if (seconds === 0 && intervalId !== null) {
      clearInterval(intervalId);
    }
  }, [seconds, intervalId]);

  const formatTime = (time: number): string => {
    const minutes: number = Math.floor(time / 60);
    const remainingSeconds: number = time % 60;
    return `${minutes} min ${remainingSeconds.toLocaleString('en-US', { minimumIntegerDigits: 2 })} sec`;
  };

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }
  useEffect(()=>{
    if(`${otp}`.length == 4){
      handleVerifyOtp();
    }
  },[otp]);

  const handleVerifyOtp = ()=>{
    console.log('OTP SENT::::',props.otpCode, otp );
    if(otp && props.otpCode && otp === props.otpCode ){
      props.loginFunct();
    }else {
      toast(
  
          !otp?"Kindly ensure otp field is not empty":otpErrorMessage,
        {
          type: ErrorSeverityEnum.error,
        }
      );

    }

  }

  return (
    <Column crossAxisAlignment='center'>
      <Helmet>
        <title>OTP</title>
      </Helmet>
      <Typography variant='body1' sx={{color: "#4f4f4f"}}>Please enter the 4-digit code</Typography>
      {/* <MuiOtpInput value={otp} onChange={handleChange} sx={{width:"300px", height:"100px"}}/> */}
      <TextField fullWidth margin="normal" label="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)}  />

      <Typography variant='body1' sx={{color: "#8c8c8c", margin:"20px 0"}}>Code Expires in:</Typography>
      <Typography variant='body1' sx={{color: "#8c8c8c", margin:"20px 0"}}>{formatTime(seconds)}</Typography>

    <Button variant="contained"sx={{
      width:"200px", maxWidth:"500px", height:"48px", borderRadius:"100rem", backgroundColor:"#0CAF6D",
      marginTop:"20px"
    }} onClick={()=>handleVerifyOtp()}>
      Continue
    </Button>
    <Button variant="contained"sx={{
      width:"200px", maxWidth:"500px", height:"48px", borderRadius:"100rem", backgroundColor:"#c4c4c4",
      marginTop:"20px"
    }} onClick={()=>{props.handleSendOtp(); resetTimer();}} disabled={seconds>0}>
      Resend
    </Button>
    </Column>
    
  )
}
