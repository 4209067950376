import React, { useState } from 'react';
import styled from 'styled-components';

interface FilterProps {
  years: number[]; // Available years
  onFilterChange: (year: number | null, month: string | null) => void;
}

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const YearMonthFilter: React.FC<FilterProps> = ({ years, onFilterChange }) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [visibleMonthStartIndex, setVisibleMonthStartIndex] = useState(0); // For cycling months
  const [visibleYearStartIndex, setVisibleYearStartIndex] = useState(0); // For cycling years

  const handleYearClick = (year: number) => {
    setSelectedYear(year);
    onFilterChange(year, selectedMonth);
  };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
    onFilterChange(selectedYear, month);
  };

  const handleMonthPrevClick = () => {
    setVisibleMonthStartIndex((prevIndex) => Math.max(0, prevIndex - 4)); // Show previous 4 months
  };

  const handleMonthNextClick = () => {
    setVisibleMonthStartIndex((prevIndex) => Math.min(months.length - 4, prevIndex + 4)); // Show next 4 months
  };

  const handleYearPrevClick = () => {
    setVisibleYearStartIndex((prevIndex) => Math.max(0, prevIndex - 4)); // Show previous 4 years
  };

  const handleYearNextClick = () => {
    setVisibleYearStartIndex((prevIndex) => Math.min(years.length - 4, prevIndex + 4)); // Show next 4 years
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', flexWrap:"wrap" }}>
      {/* Year Selector */}
      <div
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        
      >
        {/* Left Arrow for Years */}
        <div
          id="year-arrow-container"
          style={{ position: 'absolute', left: '-30px', cursor: 'pointer' }}
        >
          <StyledButton onClick={handleYearPrevClick} style={{ border: 'none', background: 'none' }}>◀</StyledButton>
        </div>

        {/* Visible Years */}
        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
          {years.slice(visibleYearStartIndex, visibleYearStartIndex + 4).map((year) => (
            <button
              key={year}
              onClick={() => handleYearClick(year)}
              style={{
                padding: '10px',
                margin: '5px',
                backgroundColor: selectedYear === year ? 'lightblue' : 'white',
                border: "none",
                fontWeight:"600",
                fontSize:"16px",
                color:"#5F6462"
              }}
            >
              {year}
            </button>
          ))}
        </div>

        {/* Right Arrow for Years */}
        <div
          id="year-arrow-container"
          style={{  position: 'absolute', right: '-30px', cursor: 'pointer' }}
        >
          <StyledButton onClick={handleYearNextClick} style={{ border: 'none', background: 'none' }}>▶</StyledButton>
        </div>
      </div>

      {/* Month Selector */}
      <div
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        {/* Left Arrow for Months */}
        <div
          id="month-arrow-container"
          style={{ position: 'absolute', left: '-30px', cursor: 'pointer' }}
        >
          <StyledButton onClick={handleMonthPrevClick} >◀</StyledButton>
        </div>

        {/* Visible Months */}
        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
          {months.slice(visibleMonthStartIndex, visibleMonthStartIndex + 4).map((month) => (
            <button
              key={month}
              onClick={() => handleMonthClick(month)}
              style={{
                padding: '10px',
                margin: '5px',
                backgroundColor: selectedMonth === month ? 'lightblue' : 'white',
                border: "none",
                fontWeight:"600",
                fontSize:"16px",
                color:"#5F6462"
              }}
            >
              {month.toUpperCase()}
            </button>
          ))}
        </div>

        {/* Right Arrow for Months */}
        <div
          id="month-arrow-container"
          style={{ position: 'absolute', right: '-30px', cursor: 'pointer' }}
        >
          <StyledButton onClick={handleMonthNextClick} >▶</StyledButton>
        </div>
      </div>
    </div>
  );
};

const StyledButton = styled.button`
border: none;
background: none;
color: #E3E3E3;
&:hover {
    color: #5f5f5f;
    cursor: pointer;
}
`