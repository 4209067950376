import { Country, CountryCodes } from "./types";

export function getGhanaianNetworkProvider(phoneNumber: string) {
    // Regular expression to match Ghanaian phone numbers with or without country code
    const ghanaNumberRegex = /^(?:\+233|233|0)?(20[0-9]|50[0-9]|24[0-9]|54[0-9]|27[0-9]|57[0-9]|23[0-9]|55[0-9]|26[0-9]|56[0-9]|28[0-9]|58[0-9]|22[0-9]|21[0-9]|25[0-9]|59[0-9]|29[0-9]|60[0-9]|61[0-9]|62[0-9]|63[0-9]|64[0-9]|65[0-9]|66[0-9]|67[0-9]|68[0-9]|69[0-9]|30[0-9]|31[0-9]|32[0-9]|33[0-9]|34[0-9]|35[0-9]|36[0-9]|37[0-9]|38[0-9]|39[0-9]|80[0-9]|81[0-9]|82[0-9]|83[0-9]|84[0-9]|85[0-9]|86[0-9]|87[0-9]|88[0-9]|89[0-9]|90[0-9]|91[0-9]|92[0-9]|93[0-9]|94[0-9]|95[0-9]|96[0-9]|97[0-9]|98[0-9]|99[0-9])[0-9]{6}$/;
  
    // Check if the input matches the Ghanaian number pattern
    if (ghanaNumberRegex.test(phoneNumber)) {
      // Extract the network provider code
      const networkCode = phoneNumber.replace(/\D/g, '').substring(3, 5);
  
      // Map network provider codes to actual network names
      const networkProviders: any = {
        '20': 'vodafone',
        '50': 'vodafone',
        '55': 'mtn',
        '54': 'mtn',
        '53': 'mtn',
        '24': 'mtn',
        '27': 'airtelTigo',
        '26': 'airtelTigo',
        // Add more network codes as needed
      };
  
      // Check if the network code is in the mapping
      if (networkProviders.hasOwnProperty(networkCode)) {
        return networkProviders[networkCode];
      } else {
        return 'Unknown Network Provider';
      }
    } else {
      return 'Not a valid Ghanaian phone number';
    }
  }
  
  export const countryCodes: CountryCodes = {
    "1": { code: "+1", name: "USA" },
    "44": { code: "+44", name: "UK" },
    "233": { code: "+233", name: "Ghana" },
    "234": { code: "+234", name: "Nigeria" },
    "27": { code: "+27", name: "South Africa" },
    "20": { code: "+20", name: "Egypt" },
    "49": { code: "+49", name: "Germany" }
  };

  export const formatPhoneNumber = (inputNumber?: string): string => {

    if(!inputNumber){
      return "";
    }
    // Remove all non-digit characters from input
    const cleanedNumber: string = inputNumber.replace(/\D/g, '');

    // If the number starts with 0, remove the leading zeros
    let formattedNumber: string = cleanedNumber.startsWith('0') ? cleanedNumber.replace(/^0+/, '') : cleanedNumber;

    // Find the country code based on the formattedNumber
    let countryCode: string = '';
    Object.keys(countryCodes).forEach((code: string) => {
      if (formattedNumber.startsWith(code)) {
        countryCode = code;
      }
    });

    // Format phone number based on country code
    if (countryCode) {
      const { code, name } = countryCodes[countryCode];
      formattedNumber = formattedNumber.slice(countryCode.length);
      const formattedNumberWithDashes: string = formattedNumber.length > 9 ?
        formattedNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3') :
        formattedNumber.replace(/^(\d{2})(\d{3})(\d{4})$/, '$1-$2-$3');
      return `${code} ${formattedNumberWithDashes}`;
    } else {
      // Default format if country code not found
      return `+${formattedNumber}`;
    }
};



  export const stripPhoneNumber = (inputNumber: string, removeCountryCode?: boolean): string => {
    // Remove all non-digit characters from input
    const cleanedNumber: string = inputNumber.replace(/\D/g, '');
  
    // If the number starts with a country code, remove it
    const countryCodess: string[] = Object.keys(countryCodes);
    for (const code of countryCodess) {
      if (cleanedNumber.startsWith(code) && removeCountryCode) {
        return cleanedNumber.slice(code.length);
      }
    }
  
    return cleanedNumber;
  };
  